import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo_color.svg";
import Dropdown from "../../components/Dropdown";
import { useEffect, useRef, useState } from "react";
import Rodape from "../../components/Rodape";
import InputText from "../../components/InputText";
import IMask from "imask";
import InputMask from "react-input-mask";
import bcrypt from 'bcryptjs'

import LogoColor from "../../assets/image/logo_color.svg";
import ImageUploader from "../../components/DropdownZone";
import PasswordInput from "../../components/PasswordInput";
import { BuscaVeiculoAgenciaByCNPJ } from "../../models/buscaAgencia";
import DropdownAgencia from "../../components/DropdownVeiculo";


const CadastroVisitante = () => {

    const navigation = useNavigate();

    const [nome, setNome] = useState("");
    const [sobrenome, setSobrenome] = useState("");
    const [identificacao, setIdentificacao] = useState("");
    const [tipoIdentificacao, setTipoIdentificacao] = useState("cpf");
    const [sexo, setSexo] = useState("");
    const [escolaridade, setEscolaridade] = useState("");
    const [compartilhaDados, setCompartilhaDados] = useState(false);
    const [nascimento, setNascimento] = useState("");
    const [nacionalidade, setNacionalidade] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [veiculoAgencia, setVeiculoAgencia] = useState([]);


    const [cnpj, setCnpj] = useState(null)


    const [tipoTurismo, setTipoTurismo] = useState("");
    const [empresa, setEmpresa] = useState("");
    const [veículoempresa, setVeículoEmpresa] = useState("");

    const [embarque, setEmbarque] = useState("");

    const visitante = {
        nome: nome,
        sobrenome: sobrenome,
        nacionalidade: nacionalidade,
        tipoIdentificacao: tipoIdentificacao,
        identificacao: identificacao,
        sexo: sexo,
        data_nascimento: nascimento,
        escolaridade: escolaridade,
        compartilha_dados: compartilhaDados,
        email,
        password: newpassword,
        empresa: empresa,
        cnpj: cnpj,
        veículo_empresa: veículoempresa,
        tipo_turismo: tipoTurismo,

        embarque: embarque,

        //aqui vc tem que adicionar os campos que vc vai add  pra poder salvar na etapa2
        //cada pagina do cadastro é uma etapa
        //possui um total de 5 etapas, onde cada uma armazena os dados da pagina e na ultima eu busco todos os dados para enviar para o servidor
    };

    useEffect(() => {
        const fetchdata = async () => {
            setVeiculoAgencia([]);
            const res = await BuscaVeiculoAgenciaByCNPJ(cnpj);


            if (res.veiculo.length > 0) {
                setVeiculoAgencia(res.veiculo);
            }
        }
        if (cnpj && cnpj.length > 11) {
            fetchdata();
        }
    }, [cnpj])



    const handleAvanca = async () => {

        try {

            if (compartilhaDados) {
                localStorage.setItem("@cadastro:Etapa2", JSON.stringify({
                    visitante: {
                        nome,
                        sobrenome,
                        nacionalidade,
                        tipoIdentificacao,
                        identificacao,
                        sexo,
                        data_nascimento: nascimento,
                        escolaridade,
                        compartilha_dados: compartilhaDados,
                        email,
                        password: newpassword,
                        empresa,
                        cnpj,
                        veículo_empresa: veículoempresa,
                        tipo_turismo: tipoTurismo,
                        embarque: embarque,
                    }
                }));

                navigation("/acompanhante");
            } else {
                alert("Aceite os Termos e Condições para prosseguir.");
            }

        }
        catch (error) {
            console.log("caiu no erro: ", error)

        }

    }


    useEffect(() => {

        //pega os dados salvos na pagina anterior
        const aux = JSON.parse(localStorage.getItem("@cadastro:Etapa1"));

        //atribui o valor do tipo turismo na pagina anterior a variavel local
        setTipoTurismo(aux.tipo_turismo);


        const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa2"));
        if (dados != null) {
            setNome(dados.visitante.nome);
            setSobrenome(dados.visitante.sobrenome);
            setNacionalidade(dados.visitante.nacionalidade);
            setTipoIdentificacao(dados.visitante.tipoIdentificacao);
            setIdentificacao(dados.visitante.identificacao);
            setSexo(dados.visitante.sexo);
            setNascimento(dados.visitante.data_nascimento);
            setEscolaridade(dados.visitante.escolaridade);
            setCompartilhaDados(dados.visitante.compartilha_dados);
            setEmpresa(dados.visitante.empresa);
            setVeículoEmpresa(dados.visitante.veículoempresa);
            setEmbarque(dados.visitante.embarque);
        }
    }, []);

    const opcoes = [
        { key: 1, value: 'Sem Escolaridade' },
        { key: 2, value: 'Ensino Fundamental' },
        { key: 3, value: 'Ensino Medio' },
        { key: 4, value: 'Ensino Superior' },
        { key: 5, value: 'Graduado' },
        { key: 6, value: 'Mestrado' },
        { key: 7, value: 'Doutorado' }
    ];

    const opcoes2 = [
        { key: 1, value: 'Onibus 1' },
        { key: 2, value: 'Onibus 2' },
        { key: 3, value: 'Onibus 3' },
        { key: 4, value: 'Onibus 4' }
    ];

    const opcoes3 = [
        { key: 1, value: 'Brasileiro(a)' },
        { key: 2, value: 'Estrangeiro(a)' },
    ];


    const maskOptions = {
        mask: "000.000.000-00"
    }
    const maskRef = useRef(null);

    useEffect(() => {
        if (tipoIdentificacao == "cpf") {
            maskRef.current = IMask(document.getElementById("visitanteCPF"), maskOptions);
        }
    }, [tipoIdentificacao]);


    const maskOptionsCnpj = {
        mask: "00.000.000/0000-00"
    }

    useEffect(() => {
        if (cnpj) {
            maskRef.current = IMask(document.getElementById("visitanteCNPJ"), maskOptionsCnpj);
        }

    }, [cnpj]);

    // Inside the useEffect
    // const createPasswordHash = async (password) => {
    //     try {
    //         const hashedPassword = await new Promise((resolve, reject) => {
    //             bcrypt.hash(password, 8, (err, hash) => {
    //                 if (err) reject(err);
    //                 resolve(hash);
    //             });
    //         });
    //         setNewPassword(hashedPassword);

    //     } catch (error) {
    //         console.log("Error occurred while hashing password:", error);
    //     }
    // };

    // useEffect(() => {

    //     createPasswordHash(password);

    // }, [password])

    return (
        <form className="container" onSubmit={handleAvanca}>

            <section className="home_topo_conteudo">
                <img src={LogoColor} alt="logo" className="logoColor" />
                <img src={LogoColor} alt="logo" className="logo" />
            </section>



            <div className="corpo flex">

                <div className="text1-black">Cadastro do visitante</div>
                <div className="text2">Preencha os dados do perfil. Isso levará alguns segundos.<br /><strong >Depois ganhe tempo na visitação dos pontos turísticos!</strong></div>

                <div>


                    <div>

                        <div className="titleinput">Documentação</div>
                        <div className="col-2 flex">
                            <div>
                                <input type="radio" value="cpf" name="ident" checked={tipoIdentificacao === "cpf"} onChange={item => setTipoIdentificacao(item.target.value)} />
                                <label className="textRadio">CPF</label>
                            </div>
                            <div>
                                <input type="radio" value="passaporte" name="ident" checked={tipoIdentificacao === "passaporte"} onChange={item => setTipoIdentificacao(item.target.value)} />
                                <label className="textRadio">Passaporte</label>
                            </div>
                        </div>

                        {tipoIdentificacao === "cpf" &&

                            <InputText
                                id="visitanteCPF"
                                // title="CPF"
                                placeholder="Número do CPF"
                                mask={maskOptions}
                                value={identificacao}
                                onchange={event => setIdentificacao(event.target.value)}
                                msnWarning="Campo precisa ser preenchido"
                                required={true}
                            />

                        }

                        {tipoIdentificacao === "passaporte" &&
                            <InputText
                                // id= "visitanteCPF"

                                // title="CPF"
                                placeholder="Número do passaporte"
                                value={identificacao}
                                onchange={item => setIdentificacao(item.target.value)}
                                msnWarning="Campo precisa ser preenchido"
                                required={true}
                            />
                        }

                    </div>



                    <div className="containerbloco" class="col-5 flex">


                        <InputText
                            title="Nome"
                            placeholder="Primeiro nome"
                            value={nome}
                            onchange={item => setNome(item.target.value)}
                            msnWarning="Obrigatório preencher o primeiro nome."
                            required={true}
                        />

                        <InputText
                            title="Sobrenome"
                            placeholder="Segundo nome"
                            value={sobrenome}
                            onchange={item => setSobrenome(item.target.value)}
                            msnWarning="Obrigatório preencher o segundo nome"
                            required={true}
                        />

                    </div>


                    <div>

                        <div className="titleinput">Qual seu sexo</div>
                        <div className="col-2 flex">



                            <div>
                                <input type="radio" value="Masculino" name="sexo" checked={sexo === "Masculino"} onChange={item => setSexo(item.target.value)} />
                                <label className="textRadio">Masculino</label>
                            </div>
                            <div>
                                <input type="radio" value="Feminino" name="sexo" checked={sexo === "Feminino"} onChange={item => setSexo(item.target.value)} />
                                <label className="textRadio">Feminino</label>
                            </div>
                            <div>
                                <input type="radio" value="Outros" name="sexo" checked={sexo === "Outros"} onChange={item => setSexo(item.target.value)} />
                                <label className="textRadio">Outros</label>
                            </div>
                        </div>

                    </div>



                    <div>

                        <div className="textInput">Nacionalidade</div>
                        <Dropdown
                            data={opcoes3}
                            placeholder=""
                            onchange={item => setNacionalidade(item.target.value)}
                            value={nacionalidade}
                            msnWarning="Campo obrigatório."
                            required={true}
                        />


                    </div>


                    <div className="containerbloco" class="col-5 flex">

                        <InputText
                            type="date"
                            title="Data de nascimento"
                            value={nascimento}
                            onchange={item => setNascimento(item.target.value)}
                            msnWarning="Informe a data de nascimento."
                            required={true}
                        />

                        <div>
                            <div className="textInput">Escolaridade</div>
                            <Dropdown
                                title="Escolaridade"
                                data={opcoes}
                                placeholder=""
                                onchange={item => setEscolaridade(item.target.value)}
                                value={escolaridade}
                                msnWarning="Campo obrigatório."
                                required={true}
                            />


                        </div>
                    </div>



                    {/* <div className="textInput">Teste Upload</div>

    < ImageUploader /> */}

                    {tipoTurismo === "Turismo de Excursão" &&
                         <>

                         <div className="text1-black">Informações de embarque</div>

                         <div className="titleinput">Você está embarcado como:</div>
                         <div className="col-2 flex">
                             <div>
                                 <input type="radio" value="Passageiro" name="embarque" checked={embarque === "Passageiro"} onChange={item => setEmbarque(item.target.value)} />
                                 <label className="textRadio">Passageiro</label>
                             </div>
                             <div>
                                 <input type="radio" value="Guia" name="embarque" checked={embarque === "Guia"} onChange={item => setEmbarque(item.target.value)} />
                                 <label className="textRadio">Guia Turístico</label>
                             </div>
                             <div>
                                 <input type="radio" value="Motorista" name="embarque" checked={embarque === "Motorista"} onChange={item => setEmbarque(item.target.value)} />
                                 <label className="textRadio">Motorista</label>
                             </div>
                         </div>

                         {/* <div>

                             <div className="titleinput">Qual empresa responsável por sua viagem?</div>
                             <div className="espaçodetexto"></div>

                             {tipoIdentificacao === "cpf" && cnpj &&
                                 <InputText
                                     title="CNPJ da empresa"
                                     id="visitanteCNPJ"
                                     // title="CPF"
                                     placeholder="00.00.000/0000-00"
                                     value={cnpj}
                                     onchange={item => setCnpj(item.target.value)}
                                     msnWarning="Campo precisa ser preenchido"
                                     required={true}
                                 />
                             }
                         </div> */}

                         <div>

                             <div className="espaçodetexto"></div>

                            
                                 <InputText
                                     title="CNPJ da empresa"
                                     id="visitanteCNPJ"
                                     // title="CPF"
                                     placeholder="00.000.000/0000-00"
                                     value={cnpj}
                                     onchange={item => setCnpj(item.target.value)}
                                     msnWarning="Campo precisa ser preenchido"
                                     required={true}
                                 />

                         </div>

                         <div className="espaçodetexto"></div>
                         {veiculoAgencia &&
                             <>
                                 <div className="textInput">Selecione o veículo que você vai viajar</div>
                                 <DropdownAgencia
                                     data={veiculoAgencia}
                                     placeholder="Selecionar veículo"
                                     onchange={item => setVeículoEmpresa(item.target.value)}
                                     value={veículoempresa}
                                     msnWarning="Campo obrigatório."
                                     required={true}
                                 />
                             </>
                         }
                         

                     </>
                    }


                    {tipoTurismo === "Turismo Comercial" &&
                        <>
                            <div className="titleinput">Qual empresa você está representando?</div>
                            <InputText
                                title="Empresa"
                                placeholder="Digite o nome da empresa"
                                value={empresa}
                                onchange={item => setEmpresa(item.target.value)}
                                msnWarning="Obrigatório preencher o nome da empresa."
                                required={true}
                            />

                            {tipoIdentificacao === "cpf" &&
                                <InputText
                                    title="CNPJ da empresa"
                                    id="visitanteCNPJ"
                                    // title="CPF"
                                    placeholder="00.000.000/0000-00"
                                    value={cnpj}
                                    onchange={item => setCnpj(item.target.value)}
                                    msnWarning="Campo precisa ser preenchido"
                                    required={true}

                                />
                            }
                        </>

                    }


                    <div className="containerlogin" >

                        {/* <div>
                            <div className="titlelogin">Faça seu cadastro</div>
                            <InputText
                                type="email"
                                title="Preencha uma única vez fazendo seu cadastro"
                                placeholder="Digite seu Email"
                                value={email}
                                onchange={(item) => setEmail(item.target.value)}
                                msnWarning="Informe seu email."
                                required={true}
                            />

                            <PasswordInput
                                id="current-password"
                                name="confirmpassword"
                                type="password"
                                title="Digite a Senha"
                                placeholder="Digite a Senha"
                                value={confirmPassword}
                                onchange={(item) => setConfirmPassword(item.target.value)}
                                msnWarning="confirme sua Senha."
                                required={true}
                            />
                        </div> */}


                    </div>

                    <div className="containerPolitica flex">
                        <input type="checkbox" className="checkbox" checked={compartilhaDados} onChange={item => setCompartilhaDados(item.target.checked)} />
                        <div>
                            <div className="politica">Compartilhar meus dados de registro com nossos provedores de conteúdo para
                                fins de analíses turísticas.</div>
                            <div className="politica"><br />Ao preencher os dados, você concorda com os <Link className="a" to={"/TermosdeUso"}><strong> Termos de uso</strong></Link>, 
                            <Link className="a" to={""}><strong>Termos do Município</strong></Link> e <Link className="a" to={"/PoliticaDePrivacidade"}><strong>Política de Privacidade.</strong></Link></div>
                        </div>
                    </div>


                </div>

            </div>

            <Rodape
                valorBar={49.8}
                voltar={() => navigation("/novo")}
                avancar={true}
            />

        </form>
    )
}

export default CadastroVisitante;