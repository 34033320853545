import ProgressBar from "@ramonak/react-progress-bar";
import "./Rodape.css";
const Rodape = ({ voltar, avancar, valorBar }) => {
    return (
        <section className="rodape">
            <ProgressBar
                completed={valorBar}
                bgColor="#222222"
                height="5px"
                borderRadius="0"
                isLabelVisible={false}
                labelColor="#e8090"
                baseBgColor="#fff"
                transitionTimingFunction="linear"
                animateOnRender
                maxCompleted={100}

            />
            <div className="rodapebtns">
                {voltar &&
                    <button2 className="button" onClick={voltar}>Voltar</button2>
                }
                {avancar &&
                    <button type="submit" className="button">Avançar</button>
                }
            </div>

        </section>
    );
}

export default Rodape;