import { useEffect, useRef, useState } from "react";
import Logo from "../../assets/image/logo.svg";
import LogoColor from "../../assets/image/logo_color.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Rodape from "../rodape";
import { BuscaClientes_Cidades } from "../../models/buscaCidades";
import { useAuth } from "../../service/JwtContext";
import { autentication } from "../../utils/config";

const tempArrayCidade = [
    {
        id: 1,
        cidade: "Capitólio",
        estado: "Minas Gerais, Brasil",
        imagem_nome: "img_capitolio"
    },
    {
        id: 2,
        cidade: "São josé da Barra",
        estado: "Minas Gerais, Brasil",
        imagem_nome: "img_barra"
    },
    {
        id: 3,
        cidade: "S.J.B do Glória",
        estado: "Minas Gerais, Brasil",
        imagem_nome: "img_gloria"
    },
 ];


const Home = () => {

    const [ cidades, setCidades] = useState();


    const carousel = useRef();
    const [width, setwidth] = useState(0);

    const auth = useAuth();

    const AutenticacaoWebSite = async () =>{
        try{
            await auth.login(autentication.login, autentication.password);
        }catch(e){
            console.log("Deu erro", e);
        }
    }

    useEffect(() => {
        setwidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)

        const buscaCidades = async () =>{
            
            const  res  = await BuscaClientes_Cidades();

            if(res){
                setCidades(res);
            }
        };

        AutenticacaoWebSite();

        buscaCidades();

    }, [])



    return (

        <section className="container">
            <section className="home_topo">
                <div className="home_topo_conteudo2">
                    <img src={LogoColor} alt="logo" className="logoColor" />
                    <img src={Logo} alt="logo" className="logo" />
                    <div className="titulo"><strong>Sllips.</strong> Destino seguro, <br /> experiência completa!</div>
                    <label className="subtitulo">Viva várias experiências com um único cadastro!</label>
                </div>
 
            </section>
            <section className="home_corpo">
                <div className="pesquisa">
                    <div className="text1">Destinos seguros</div>
                    <div className="text2">Trabalhamos com destinos que buscam segurança para seus visitantes viverem experiências incríveis!</div>
                    <div className="text3">Qual seu próximo destino?</div>
                    <input className="input" placeholder="Nome do destino" />
                </div>

                {cidades &&
                    <div className="resposta">
                        <motion.div ref={carousel} className="carousel" whileTap={{ cursor: "grabbing" }}>
                            
                            <motion.div
                                drag="x"
                                dragConstraints={{ right: 0, left: -width }}
                                className="inner flex">

                                {cidades.map(item => (
                                <Link className="box" to={`/cadastro/${item.id}/${item.cidade}`}>
                                    <motion.div className="item" key={item.id}>

                                        
                                            <img src={`/imagens/${item.nome_imagem}.png`} alt="Imagem" />
                                            <div className="Inf">
                                                <strong>{item.cidade}</strong>
                                                {item.estado}
                                            </div>
                                        
                                        
                                    </motion.div>
                                </Link>
                                ))}

                            </motion.div>
                        </motion.div>
                    </div>
                }
                
            </section>


          <Rodape />


        </section>
    )
}
export default Home;


//// old

// <motion.div ref={carousel} className="carousel" whileTap={{ cursor: "grabbing" }}>
// <motion.div
//     drag="x"
//     dragConstraints={{ right: 0, left: -width }}
//     className="inner flex">
//     {imagens.map(item => (
//         <motion.div className="item" key={item}>
//             <img src={item} alt="imagen" />
//             <Link className="box" to="/cadastro">
//                 <div className="Inf"><strong>Capitólio</strong>Minas Gerais, Brasil</div>
//             </Link>
//         </motion.div>
//     ))}


//     {imagens2.map(item => (
//         <motion.div className="item" key={item}>
//             <img src={item} alt="imagen" />
//             <Link className="box" to="/cadastro">
//                 <div className="Inf"><strong>São José da Barra</strong>Minas Gerais, Brasil</div>
//             </Link>
//         </motion.div>
//     ))}

//     {imagens3.map(item => (
//         <motion.div className="item" key={item}>
//             <img src={item} alt="imagen" />
//             <Link className="box" to="/cadastro">
//                 <div className="Inf"><strong>S.J.B. do Glória</strong>Minas Gerais, Brasil</div>
//             </Link>
//         </motion.div>
//     ))}

//     {imagens4.map(item => (
//         <motion.div className="item" key={item}>
//             <img src={item} alt="imagen" />
//             <Link className="box" to="/cadastro">
//                 <div className="Inf"><strong>Guapé</strong>Minas Gerais, Brasil</div>
//             </Link>
//         </motion.div>
//     ))}



// </motion.div>
// </motion.div>