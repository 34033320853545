export const paises = [
    { "id": 1, "name": "Afeganistão", "code": "AF" },
    { "id": 2, "name": "Albânia", "code": "AL" },
    { "id": 3, "name": "Argélia", "code": "DZ" },
    { "id": 4, "name": "Andorra", "code": "AD" },
    { "id": 5, "name": "Angola", "code": "AO" },
    { "id": 6, "name": "Antígua e Barbuda", "code": "AG" },
    { "id": 7, "name": "Argentina", "code": "AR" },
    { "id": 8, "name": "Armênia", "code": "AM" },
    { "id": 9, "name": "Austrália", "code": "AU" },
    { "id": 10, "name": "Áustria", "code": "AT" },
    { "id": 11, "name": "Azerbaijão", "code": "AZ" },
    { "id": 12, "name": "Bahamas", "code": "BS" },
    { "id": 13, "name": "Bahrain", "code": "BH" },
    { "id": 14, "name": "Bangladesh", "code": "BD" },
    { "id": 15, "name": "Barbados", "code": "BB" },
    { "id": 16, "name": "Belarus", "code": "BY" },
    { "id": 17, "name": "Bélgica", "code": "BE" },
    { "id": 18, "name": "Belize", "code": "BZ" },
    { "id": 19, "name": "Benin", "code": "BJ" },
    { "id": 20, "name": "Bhutan", "code": "BT" },
    { "id": 21, "name": "Bolívia", "code": "BO" },
    { "id": 22, "name": "Bósnia e Herzegovina", "code": "BA" },
    { "id": 23, "name": "Botswana", "code": "BW" },
    { "id": 24, "name": "Brasil", "code": "BR" },
    { "id": 25, "name": "Brunei", "code": "BN" },
    { "id": 26, "name": "Bulgária", "code": "BG" },
    { "id": 27, "name": "Burkina Faso", "code": "BF" },
    { "id": 28, "name": "Burundi", "code": "BI" },
    { "id": 29, "name": "Cabo Verde", "code": "CV" },
    { "id": 30, "name": "Camboja", "code": "KH" },
    { "id": 31, "name": "Camarões", "code": "CM" },
    { "id": 32, "name": "Canadá", "code": "CA" },
    { "id": 33, "name": "República Centro-Africana", "code": "CF" },
    { "id": 34, "name": "Chade", "code": "TD" },
    { "id": 35, "name": "Chile", "code": "CL" },
    { "id": 36, "name": "China", "code": "CN" },
    { "id": 37, "name": "Colômbia", "code": "CO" },
    { "id": 38, "name": "Comores", "code": "KM" },
    { "id": 39, "name": "Congo", "code": "CG" },
    { "id": 40, "name": "Costa Rica", "code": "CR" },
    { "id": 41, "name": "Croácia", "code": "HR" },
    { "id": 42, "name": "Cuba", "code": "CU" },
    { "id": 43, "name": "Chipre", "code": "CY" },
    { "id": 44, "name": "República Tcheca", "code": "CZ" },
    { "id": 45, "name": "Dinamarca", "code": "DK" },
    { "id": 46, "name": "Djibouti", "code": "DJ" },
    { "id": 47, "name": "Dominica", "code": "DM" },
    { "id": 48, "name": "República Dominicana", "code": "DO" },
    { "id": 49, "name": "Timor-Leste", "code": "TL" },
    { "id": 50, "name": "Equador", "code": "EC" },
    { "id": 51, "name": "Egito", "code": "EG" },
    { "id": 52, "name": "El Salvador", "code": "SV" },
    { "id": 53, "name": "Guiné Equatorial", "code": "GQ" },
    { "id": 54, "name": "Eritreia", "code": "ER" },
    { "id": 55, "name": "Estônia", "code": "EE" },
    { "id": 56, "name": "Eswatini", "code": "SZ" },
    { "id": 57, "name": "Etiópia", "code": "ET" },
    { "id": 58, "name": "Fiji", "code": "FJ" },
    { "id": 59, "name": "Finlândia", "code": "FI" },
    { "id": 60, "name": "França", "code": "FR" },
    { "id": 61, "name": "Gabão", "code": "GA" },
    { "id": 62, "name": "Gâmbia", "code": "GM" },
    { "id": 63, "name": "Geórgia", "code": "GE" },
    { "id": 64, "name": "Alemanha", "code": "DE" },
    { "id": 65, "name": "Gana", "code": "GH" },
    { "id": 66, "name": "Grécia", "code": "GR" },
    { "id": 67, "name": "Granada", "code": "GD" },
    { "id": 68, "name": "Guatemala", "code": "GT" },
    { "id": 69, "name": "Guiné", "code": "GN" },
    { "id": 70, "name": "Guiné-Bissau", "code": "GW" },
    { "id": 71, "name": "Guiana", "code": "GY" },
    { "id": 72, "name": "Haiti", "code": "HT" },
    { "id": 73, "name": "Honduras", "code": "HN" },
    { "id": 74, "name": "Hungria", "code": "HU" },
    { "id": 75, "name": "Islândia", "code": "IS" },
    { "id": 76, "name": "Índia", "code": "IN" },
    { "id": 77, "name": "Indonésia", "code": "ID" },
    { "id": 78, "name": "Irã", "code": "IR" },
    { "id": 79, "name": "Iraque", "code": "IQ" },
    { "id": 80, "name": "Irlanda", "code": "IE" },
    { "id": 81, "name": "Israel", "code": "IL" },
    { "id": 82, "name": "Itália", "code": "IT" },
    { "id": 83, "name": "Jamaica", "code": "JM" },
    { "id": 84, "name": "Japão", "code": "JP" },
    { "id": 85, "name": "Jordânia", "code": "JO" },
    { "id": 86, "name": "Cazaquistão", "code": "KZ" },
    { "id": 87, "name": "Quênia", "code": "KE" },
    { "id": 88, "name": "Kiribati", "code": "KI" },
    { "id": 89, "name": "Coreia do Norte", "code": "KP" },
    { "id": 90, "name": "Coreia do Sul", "code": "KR" },
    { "id": 91, "name": "Kuwait", "code": "KW" },
    { "id": 92, "name": "Quirguistão", "code": "KG" },
    { "id": 93, "name": "Laos", "code": "LA" },
    { "id": 94, "name": "Letônia", "code": "LV" },
    { "id": 95, "name": "Líbano", "code": "LB" },
    { "id": 96, "name": "Lesoto", "code": "LS" },
    { "id": 97, "name": "Libéria", "code": "LR" },
    { "id": 98, "name": "Líbia", "code": "LY" },
    { "id": 99, "name": "Liechtenstein", "code": "LI" },
    { "id": 100, "name": "Lituânia", "code": "LT" },
    { "id": 101, "name": "Luxemburgo", "code": "LU" },
    { "id": 102, "name": "Madagascar", "code": "MG" },
    { "id": 103, "name": "Malawi", "code": "MW" },
    { "id": 104, "name": "Malásia", "code": "MY" },
    { "id": 105, "name": "Maldivas", "code": "MV" },
    { "id": 106, "name": "Mali", "code": "ML" },
    { "id": 107, "name": "Malta", "code": "MT" },
    { "id": 108, "name": "Ilhas Marshall", "code": "MH" },
    { "id": 109, "name": "Mauritânia", "code": "MR" },
    { "id": 110, "name": "Maurícia", "code": "MU" },
    { "id": 111, "name": "México", "code": "MX" },
    { "id": 112, "name": "Micronésia", "code": "FM" },
    { "id": 113, "name": "Moldávia", "code": "MD" },
    { "id": 114, "name": "Mônaco", "code": "MC" },
    { "id": 115, "name": "Mongólia", "code": "MN" },
    { "id": 116, "name": "Montenegro", "code": "ME" },
    { "id": 117, "name": "Marrocos", "code": "MA" },
    { "id": 118, "name": "Moçambique", "code": "MZ" },
    { "id": 119, "name": "Myanmar", "code": "MM" },
    { "id": 120, "name": "Namíbia", "code": "NA" },
    { "id": 121, "name": "Nauru", "code": "NR" },
    { "id": 122, "name": "Nepal", "code": "NP" },
    { "id": 123, "name": "Países Baixos", "code": "NL" },
    { "id": 124, "name": "Nova Zelândia", "code": "NZ" },
    { "id": 125, "name": "Nicarágua", "code": "NI" },
    { "id": 126, "name": "Níger", "code": "NE" },
    { "id": 127, "name": "Nigéria", "code": "NG" },
    { "id": 128, "name": "Niue", "code": "NU" },
    { "id": 129, "name": "Macedônia do Norte", "code": "MK" },
    { "id": 130, "name": "Noruega", "code": "NO" },
    { "id": 131, "name": "Omã", "code": "OM" },
    { "id": 132, "name": "Paquistão", "code": "PK" },
    { "id": 133, "name": "Palau", "code": "PW" },
    { "id": 134, "name": "Palestina", "code": "PS" },
    { "id": 135, "name": "Panamá", "code": "PA" },
    { "id": 136, "name": "Papua Nova Guiné", "code": "PG" },
    { "id": 137, "name": "Paraguai", "code": "PY" },
    { "id": 138, "name": "Peru", "code": "PE" },
    { "id": 139, "name": "Filipinas", "code": "PH" },
    { "id": 140, "name": "Polônia", "code": "PL" },
    { "id": 141, "name": "Portugal", "code": "PT" },
    { "id": 142, "name": "Catar", "code": "QA" },
    { "id": 143, "name": "Romênia", "code": "RO" },
    { "id": 144, "name": "Rússia", "code": "RU" },
    { "id": 145, "name": "Ruanda", "code": "RW" },
    { "id": 146, "name": "São Cristóvão e Nevis", "code": "KN" },
    { "id": 147, "name": "Santa Lúcia", "code": "LC" },
    { "id": 148, "name": "São Vicente e Granadinas", "code": "VC" },
    { "id": 149, "name": "Samoa", "code": "WS" },
    { "id": 150, "name": "San Marino", "code": "SM" },
    { "id": 151, "name": "São Tomé e Príncipe", "code": "ST" },
    { "id": 152, "name": "Arábia Saudita", "code": "SA" },
    { "id": 153, "name": "Senegal", "code": "SN" },
    { "id": 154, "name": "Sérvia", "code": "RS" },
    { "id": 155, "name": "Seychelles", "code": "SC" },
    { "id": 156, "name": "Serra Leoa", "code": "SL" },
    { "id": 157, "name": "Singapura", "code": "SG" },
    { "id": 158, "name": "Eslováquia", "code": "SK" },
    { "id": 159, "name": "Eslovênia", "code": "SI" },
    { "id": 160, "name": "Ilhas Salomão", "code": "SB" },
    { "id": 161, "name": "Somália", "code": "SO" },
    { "id": 162, "name": "África do Sul", "code": "ZA" },
    { "id": 163, "name": "Sudão do Sul", "code": "SS" },
    { "id": 164, "name": "Espanha", "code": "ES" },
    { "id": 165, "name": "Sri Lanka", "code": "LK" },
    { "id": 166, "name": "Sudão", "code": "SD" },
    { "id": 167, "name": "Suriname", "code": "SR" },
    { "id": 168, "name": "Suécia", "code": "SE" },
    { "id": 169, "name": "Suíça", "code": "CH" },
    { "id": 170, "name": "Síria", "code": "SY" },
    { "id": 171, "name": "Taiwan", "code": "TW" },
    { "id": 172, "name": "Tajiquistão", "code": "TJ" },
    { "id": 173, "name": "Tanzânia", "code": "TZ" },
    { "id": 174, "name": "Tailândia", "code": "TH" },
    { "id": 175, "name": "Togo", "code": "TG" },
    { "id": 176, "name": "Tonga", "code": "TO" },
    { "id": 177, "name": "Trinidad e Tobago", "code": "TT" },
    { "id": 178, "name": "Tunísia", "code": "TN" },
    { "id": 179, "name": "Turquia", "code": "TR" },
    { "id": 180, "name": "Turcomenistão", "code": "TM" },
    { "id": 181, "name": "Tuvalu", "code": "TV" },
    { "id": 182, "name": "Uganda", "code": "UG" },
    { "id": 183, "name": "Ucrânia", "code": "UA" },
    { "id": 184, "name": "Emirados Árabes Unidos", "code": "AE" },
    { "id": 185, "name": "Reino Unido", "code": "GB" },
    { "id": 186, "name": "Estados Unidos da América", "code": "US" },
    { "id": 187, "name": "Uruguai", "code": "UY" },
    { "id": 188, "name": "Uzbequistão", "code": "UZ" },
    { "id": 189, "name": "Vanuatu", "code": "VU" },
    { "id": 190, "name": "Vaticano", "code": "VA" },
    { "id": 191, "name": "Venezuela", "code": "VE" },
    { "id": 192, "name": "Vietnã", "code": "VN" },
    { "id": 193, "name": "Iêmen", "code": "YE" },
    { "id": 194, "name": "Zâmbia", "code": "ZM" },
    { "id": 195, "name": "Zimbábue", "code": "ZW" }
  ]
  