import "./BtnTipoCadastro.css";
import Seguir from "../assets/image/seg.svg";
const BtnTipoCadastro = ({onclick, title, descricao}) => {
    return (
        <button className="btnTpCadastro" onClick={onclick}>
            <div className="Tab1">
                <div className="btnTitulo">{title}</div>
                <div className="btnDescricao">{descricao}</div>
            </div>
            <div className="Tab2">
                <img src={Seguir} alt='Seguir' />
            </div>
        </button>
    );
}

export default BtnTipoCadastro;