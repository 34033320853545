import React, { useState } from 'react';
import axios from 'axios';

const ImageUploader = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('imageName', file.name); // Adiciona o nome original da imagem ao FormData

      const response = await axios.post('http://localhost:8001/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // console.log('Imagem enviada com sucesso:', response.data.imagePath);
    } catch (error) {
      console.error('Erro ao enviar a imagem:', error.message);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Enviar Imagem</button>
    </div>
  );
};

export default ImageUploader;
