import axios from "axios";

// const url = 'http://192.168.0.19:5000'
const url = 'https://sllips.com.br/api'
//const url = 'http://192.168.1.128:8001'

//localhost
//const url = 'http://localhost:8001'

//casa
//const url = 'http://192.168.15.103:8001'

const API = axios.create({
    baseURL: url,
    timeout:5000,
    timeoutErrorMessage:`Web Service não localizado!!! verificar.... URL: ${url}`,
    headers: {
        post: {
            'Content-Type': 'application/json'
        }
    }
});


API.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Web Service não localizado')
);


export default API;
