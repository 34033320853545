import Logo from "../../assets/image/logo.svg";
import LogoColor from "../../assets/image/logo_color.svg";
import GooglePlay from "../../assets/image/googlePlay.svg";
import Appstore from "../../assets/image/appStore.svg";

import Facebook from "../../assets/image/facebook.svg";
import Instagram from "../../assets/image/instagram.svg";
import Twiter from "../../assets/image/twiter.svg";
import { Link } from "react-router-dom";
import Rodape from "../rodape";


import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";


const IdentidadeMunicipal = () => {
    

    const carousel = useRef();
    const [width, setwidth] = useState(0);

    useEffect(() => {
        setwidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
    }, [])



    return (

        <section className="container">

            <section className="identidademunicipal">
                <div className="pesquisa">

                <img src={LogoColor} alt="Logo" />
                    <p/>

                    <div>
                        <div className="PL">Termos Legais</div>
                        <div className="ID-">Privacidade</div>
                    </div>
                    <div className="ID2">

                        <p /><strong>Política de Privacidade</strong>


                        <p />Nossa Política de Privacidade explica quais informações pessoais coletamos, 
                        como usamos as informações pessoais, como as informações pessoais são compartilhadas e os direitos de privacidade.

                        <p /><strong>América Latina</strong>

                        <ul>
                            
                            <p /><li><strong><Link className="box" to="/PoliticaDePrivacidade">Política de Privacidade para a América Latina (em português).</Link></strong></li>
                            <p /><li><strong><Link className="box" to="/TermosdeUso">Termos de Uso.</Link></strong></li>
                        </ul>

                        <div className="linha"></div>

                        <p /><strong>Porque fazer o Cadastro Turístico Municipal(CTM)</strong>

                        <ul>
                            <p /><Link className="box" to="/CadastroTurísticoMunicipal">A segurança é importante para nós. Confira o que compartilhamos para manter sua identidade segura.</Link>

                        </ul>


                    </div>

                    <div className="linha"></div>




                </div>

            </section>

            <Rodape />

            
        </section>
    )
}

export default IdentidadeMunicipal;