import Logo from "../../assets/image/logo.svg";
import LogoColor from "../../assets/image/logo_color.svg";
import GooglePlay from "../../assets/image/googlePlay.svg";
import Appstore from "../../assets/image/appStore.svg";

import Facebook from "../../assets/image/facebook.svg";
import Instagram from "../../assets/image/instagram.svg";
import Twiter from "../../assets/image/twiter.svg";
import { Link } from "react-router-dom";



import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";


const IdentidadeMunicipal = () => {
    

    const carousel = useRef();
    const [width, setwidth] = useState(0);

    useEffect(() => {
        setwidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
    }, [])



    return (

        <section className="container">


            <section className="identidademunicipal">
                <div className="pesquisa">

                    <img src={LogoColor} alt="Logo" />
                    <p/>

                    <div className="ID">Por que fazer o Cadastro Turístico Municipal(CTM)</div>
                    <div className="ID2">
                        Como parte do processo de verificação de identidade, podemos pedir que você nos forneça:
                        <ul>
                            <li>Seu nome, endereço e outras informações pessoais. Muitas vezes, isso é suficiente para verificarmos sua identidade.</li>
                            <li>Foto do seu documento de identificação oficial. Pode ser a carteira de motorista, passaporte, documento de identidade ou visto. Saiba mais sobre os tipos de documento de identificação oficial.</li>
                            <li>Selfie. Talvez você precise tirar uma foto junto com seu documento de identificação oficial. Se você não conseguir fornecer uma selfie que corresponda ao seu documento de identificação oficial, pode entrar em contato conosco para usar outro método de verificação.</li>

                        </ul>
                        Como verificar sua identidade nas configurações da conta
                        Você sempre pode verificar seu documento de identificação. Basta acessar as configurações da sua conta e enviar uma foto do documento. Você pode fazer isso ao criar uma conta ou ao atualizar as informações da sua conta.
                        Observação: a realização do processo de verificação de identidade do Airbnb não representa um endosso, uma garantia da identidade de alguém ou uma garantia de que a interação com essas pessoas será segura. Procure sempre usar seu bom senso e seguir nossas dicas de segurança para hóspedes e anfitriões.
                    </div>

                    <div className="linha"></div>

                    <div className="ID">Cadastro Turístico Municipal(CTM) para check-in</div>
                    <div className="ID2">Na Sllips, a privacidade e a segurança de nossa comunidade são uma prioridade para nós.
                        <p />Estamos sempre identificando maneiras de ajudar a tornar nossa comunidade o mais segura possível para todos.
                        Nosso objetivo é que todos se sintam confiantes de que os Município e empresas cadastradas da Sllips são quem dizem ser.
                        <p />Muitas vezes, seu nome legal e endereço e/ou outras informações pessoais são suficientes para validação de sua Cadastro Turístico Municipal(CTM).
                    </div>

                    <div className="linha"></div>

                    <div className="ID">O que compartilhamos</div>
                    <div className="ID2">Suas informações de identificação são processadas de acordo com nossa Política de Privacidade e não são compartilhadas com o teceiros. Quando um municipío exige que os turistas tenham um documento de identificação, só compartilhamos:

                        <ul>
                            <p /><li><strong>Primeiro nome. </strong>Este é seu primeiro nome, tal como aparece no seu perfil.</li>
                            <li><strong>Status de verificação.</strong> Se sua identidade foi verificada.</li>

                        </ul>


                        <strong>Como gerenciamos seus dados</strong>

                        <p />Somente funcionários autorizados do Airbnb podem acessar as informações que você enviar. Todas as informações são armazenadas e transmitidas com criptografia segura. Mantemos seus dados apenas pelo tempo necessário para cumprir as leis aplicáveis e outros requisitos legais, incluindo:

                        <ul>

                            <li>Tempo necessário para atender aos requisitos de segurança e combate a fraudes</li>
                            <li>A aplicação das diretrizes da comunidade do Sllips</li>
                            <li>A conformidade com os requisitos de combate à lavagem de dinheiro</li>
                            <li>A conformidade com as leis de pagamento e tributação</li>
                            <li>Quando um titular dos dados solicita a exclusão de dados, nós processamos esse pedido de acordo com a legislação aplicável.</li>
                        </ul>
                    </div>


                </div>

            </section>



            <section className="home_rodape">
                <div className="box1 flex">


                    <div className="bloco1">

                        <div className="text1">Visitante</div>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Cadastro Turístico Municipal</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Fazer Cadastro CTM</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Ajuda e suporte</div>

                        <Link className="box" to="/InformaçõesDaEmpresa">
                            <div className="text2">Central de ajuda</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Perguntas Frequentes</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Empresa</div>

                        <Link className="box" to="/NossaHistoria">
                            <div className="text2">Nossa história</div>
                        </Link>

                    </div>
                </div>

                <div className="box2 flex">
                    <div>
                        <div className="baixe">Baixe agora</div>
                        <img src={GooglePlay} alt="Google Play" />
                    </div>
                    <div>
                        <div className="disponivel">O app esta disponível <br />apenas para atividade <br />comerciais</div>
                        <img src={Appstore} alt="App Store" />
                    </div>
                </div>
            </section>
            <section className="home_rodape">

                <div className="text">© 2022 Sllips LTDA. All rights reserved</div>

                <Link className="a" to="/Privacidade">
                    <div className="text">.Política de Privacidade</div>
                </Link>

                <Link className="a" to="/InformaçõesDaEmpresa">
                    <div className="text">.Informações da empresa</div>
                </Link>

                <div className="social">

                    <Link className="a" to="https://www.instagram.com/sllips.oficial/">
                        <img src={Instagram} alt="Instagram" />
                    </Link>
                    <img src={Facebook} alt="facebook" />
                    <img src={Twiter} alt="Twiter" />

                </div>
            </section>
        </section>
    )
}

export default IdentidadeMunicipal;