export const autentication = {
    login: 'sitesllips@sllips.com.br',
    password: '@Sllips2022'
}

export const cielo = {
    ClientId: "5ea03ff1-6a1f-403a-b4b8-b241db6a9aae",
    ClientSecret: "7ae011af-f956-4c05-8d42-6104f94d7653",
    tokenUrl: '/oauth/access-token',
    baseUrl: 'https://apisandbox.cieloecommerce.cielo.com.br',
    tokenUrlDev: "https://authsandbox.braspag.com.br/oauth2/token",
    baseUrlDev: "https://apisandbox.braspag.com.br"
}