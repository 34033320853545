import Logo from "../../assets/image/logo.svg";
import LogoColor from "../../assets/image/logo_color.svg";
import GooglePlay from "../../assets/image/googlePlay.svg";
import Appstore from "../../assets/image/appStore.svg";

import Facebook from "../../assets/image/facebook.svg";
import Instagram from "../../assets/image/instagram.svg";
import Twiter from "../../assets/image/twiter.svg";


import { Link } from "react-router-dom";
import IMask from "imask";
import InputText from "../../components/InputText";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";



const CadastroCEOM = () => {

    const navigation = useNavigate();

    const [tipoIdentificacao, setTipoIdentificacao] = useState("cpf");
    const [identificacao, setIdentificacao] = useState("")
    const [compartilhaDados, setCompartilhaDados] = useState(false);

    const ceom = {
        tipoIdentificacao: tipoIdentificacao,
        identificacao: identificacao,
        compartilha_dados: compartilhaDados,

    };



    const maskOptions = {
        mask: "000.000.000-00"
    }
    const maskRef = useRef(null);

    const handleAvanca = () => {
        localStorage.setItem("@cadastroceom:CEOM", JSON.stringify(ceom));
        navigation("/")
    }

    useEffect(() => {
        if (tipoIdentificacao == "cpf") {
            maskRef.current = IMask(document.getElementById("visitanteCPF"), maskOptions);
        }

        const dados = JSON.parse(localStorage.getItem("@cadastroceom:CEOM"));

        if (dados != null) {

            setTipoIdentificacao(dados.ceom.tipoIdentificacao);
            setIdentificacao(dados.ceom.identificacao);

        }
    }, [tipoIdentificacao, maskOptions, identificacao]);



    return (

        <form className="container" onSubmit={handleAvanca}>

            <section className="home_topoCEOM">

                <div className="home_topo_conteudoCEOM">

                    <div className="tituloCEOM"><strong>Aceite os termos de <br />nosso município!</strong> </div>
                    <label className="subtituloCEOM">Viva várias experiências com um único cadastro!</label>
                </div>

            </section>
            <section className="home_corpoCEOM">

                <div className="corpoCEOM flex">

                    <div className="titleinputCEOM">Digite seu CPF para aceitar os termos e decretos do município</div>
                    <div className="col-2 flex">
                        <div>
                            <input type="radio" value="cpf" name="ident" checked={tipoIdentificacao === "cpf"} onChange={item => setTipoIdentificacao(item.target.value)} />
                            <label className="textRadio">CPF</label>
                        </div>
                        <div>
                            <input type="radio" value="passaporte" name="ident" checked={tipoIdentificacao === "passaporte"} onChange={item => setTipoIdentificacao(item.target.value)} />
                            <label className="textRadio">Passaporte</label>
                        </div>
                    </div>
                    {tipoIdentificacao == "cpf" &&
                        <InputText
                            id="visitanteCPF"
                            // title="CPF"
                            placeholder="000.000.000-00"
                            value={identificacao}
                            onchange={item => setIdentificacao(item.target.value)}
                            msnWarning="Campo precisa ser preenchido"
                            required={true}
                        />
                    }
                    {tipoIdentificacao == "passaporte" &&
                        <InputText
                            // id= "visitanteCPF"
                            // title="CPF"
                            placeholder="Número do passaporte"
                            value={identificacao}
                            onchange={item => setIdentificacao(item.target.value)}
                            msnWarning="Campo precisa ser preenchido"
                            required={true}
                        />
                    }

                    <div className="containerPolitica flex">
                        <input type="checkbox" className="checkbox" checked={compartilhaDados} onChange={item => setCompartilhaDados(item.target.checked)} />
                        <div>
                            <div className="politica">Compartilhar meus dados de registro com nossos provedores de conteúdo para
                                fins de analíses turísticas.</div>
                            <div className="politica"><br />Ao preencher os dados, você concorda com os <Link className="a" to={"/TermosdeUso"}><strong> Termos de uso</strong></Link>,
                                <Link className="a" to={""}><strong>Termos do Município</strong></Link> e <Link className="a" to={"/PoliticaDePrivacidade"}><strong>Política de Privacidade.</strong></Link></div>
                        </div>
                    </div>

                    <button className="btncadastrar" type="submit">Salvar Cadastro</button>


                </div>



            </section>


            <section className="home_rodape">
                <div className="box1 flex">


                    <div className="bloco1">

                        <div className="text1">Visitante</div>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Cadastro Turístico Municipal</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Fazer Cadastro CTM</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Ajuda e suporte</div>

                        <Link className="box" to="/InformaçõesDaEmpresa">
                            <div className="text2">Central de ajuda</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Perguntas Frequentes</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Empresa</div>

                        <Link className="box" to="/NossaHistoria">
                            <div className="text2">Nossa história</div>
                        </Link>

                    </div>
                </div>

                <div className="box2 flex">
                    <div>
                        <div className="baixe">Baixe agora</div>
                        <img src={GooglePlay} alt="Google Play" />
                    </div>
                    <div>
                        <div className="disponivel">O app esta disponível <br />apenas para atividade <br />comerciais</div>
                        <img src={Appstore} alt="App Store" />
                    </div>
                </div>
            </section>
            <section className="home_rodape">

                <div className="text">© 2022 Sllips LTDA. All rights reserved</div>

                <Link className="a" to="/Privacidade">
                    <div className="text">.Política de Privacidade</div>
                </Link>

                <Link className="a" to="/InformaçõesDaEmpresa">
                    <div className="text">.Informações da empresa</div>
                </Link>

                <div className="social">

                    <Link className="a" to="https://www.instagram.com/sllips.oficial/">
                        <img src={Instagram} alt="Instagram" />
                    </Link>
                    <img src={Facebook} alt="facebook" />
                    <img src={Twiter} alt="Twiter" />

                </div>
            </section>


        </form>


    )
}
export default CadastroCEOM;