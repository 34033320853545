import Logo from "../../assets/image/logo.svg";
import LogoColor from "../../assets/image/logo_color.svg";
import GooglePlay from "../../assets/image/googlePlay.svg";
import Appstore from "../../assets/image/appStore.svg";

import Facebook from "../../assets/image/facebook.svg";
import Instagram from "../../assets/image/instagram.svg";
import Twiter from "../../assets/image/twiter.svg";
import { Link } from "react-router-dom";





const NossaHistoria = () => {

    return (

        <section className="container">


            <section className="nossahistoria">
                <div className="pesquisa">

                    <img src={LogoColor} alt="Logo" />

                    <div className="ID">Quem somos</div>
                    <div className="ID2">
                        Nosso objetivo é fazer com que o visitante aproveite
                        o máximo de sua viagem, fazendo-o poupar tempo
                        com preenchimento de fichas de cadastros nos pontos
                        turísticos de visitação!

                        <p />A sllips é uma plataforma
                        que conecta pessoas apaixonadas
                        por viagens que estão em busca
                        de experiências incríveis e com
                        segurança!

                        <p />Nosso desejo é que
                        todos nossos visitantes
                        aproveitem o máximo de sua viagem, tornando-a inesquecível!

                    </div>

                    <div className="linha"></div>

                    <div className="ID">Nosso Slogan</div>
                    <div className="ID2">

                        <div>

                            <strong>Destino Seguro</strong>

                            <ul>
                                <li>Todas as cidades e os pontos turísticos que estão cadastrados na sllips,
                                    foram verificados, analisados e adotaram novas medidas de segurança para atender melhor os visitantes.</li>

                            </ul>


                        </div>

                        <div>

                            <strong>Experiência completa</strong>

                            <ul>
                                <li>Nada melhor que aproveitar o máximo de cada experiência
                                    e o mais importante, sem preocupações! Nosso compromisso é
                                    oferecer destinos seguros para que
                                    nossos usurários vivenciem
                                    uma experiência completa!</li>

                            </ul>


                        </div>



                    </div>



                    <div className="ID2">

                    </div>

                    <div className="linha"></div>

                    <div className="ID">Missão, Visão e Valores</div>
                    <div className="ID2">
                        <strong>Missão</strong>

                        <ul>
                            <li>Organizar o turismo local através de dados, análises e ações.</li>

                        </ul>

                        <strong>Visão</strong>

                        <ul>
                            <li>Se tornar um instrumento imprescindível nos municípios
                                turísticos em todo o país. </li>

                        </ul>

                        <strong>Valores</strong>

                        <ul>
                            <li>Compromisso com a verdade, confiável e protetora
                                dos usuários. </li>

                        </ul>
                    </div>


                </div>

            </section>



            <section className="home_rodape">
                <div className="box1 flex">


                    <div className="bloco1">

                        <div className="text1">Visitante</div>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Cadastro Turístico Municipal</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Fazer Cadastro CTM</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Ajuda e suporte</div>

                        <Link className="box" to="/InformaçõesDaEmpresa">
                            <div className="text2">Central de ajuda</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Perguntas Frequentes</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Empresa</div>

                        <Link className="box" to="/NossaHistoria">
                            <div className="text2">Nossa história</div>
                        </Link>

                    </div>
                </div>

                <div className="box2 flex">
                    <div>
                        <div className="baixe">Baixe agora</div>
                        <img src={GooglePlay} alt="Google Play" />
                    </div>
                    <div>
                        <div className="disponivel">O app esta disponível <br />apenas para atividade <br />comerciais</div>
                        <img src={Appstore} alt="App Store" />
                    </div>
                </div>
            </section>
            <section className="home_rodape">

                <div className="text">© 2022 Sllips LTDA. All rights reserved</div>

                <Link className="a" to="/Privacidade">
                    <div className="text">.Política de Privacidade</div>
                </Link>

                <Link className="a" to="/InformaçõesDaEmpresa">
                    <div className="text">.Informações da empresa</div>
                </Link>

                <div className="social">

                    <Link className="a" to="https://www.instagram.com/sllips.oficial/">
                        <img src={Instagram} alt="Instagram" />
                    </Link>
                    <img src={Facebook} alt="facebook" />
                    <img src={Twiter} alt="Twiter" />

                </div>
            </section>
        </section>
    )
}

export default NossaHistoria;