import { useNavigate } from "react-router-dom";
import Dropdown from "../../components/Dropdown";
import Rodape from "../../components/Rodape";
import InputText from "../../components/InputText";
import { useState, useEffect } from "react";
import LogoColor from "../../assets/image/logo_color.svg";
import DropdownCity from "../../components/DropdownCity";
import DropdownUf from "../../components/DropdownUf";
import API from "../../service/api";
import axios from "axios";

import { MuiTelInput } from 'mui-tel-input'


const CadastroAcomTrans = () => {

    const navigation = useNavigate();

    const [acomodacao, setAcomodacao] = useState("");
    const [nomeAcomodacao, setNomeAcomodacao] = useState("");
    const [veiculo, setVeiculo] = useState("");
    const [placa, setPlaca] = useState("");

    const [estadouf, setEstadoUF] = useState('');
    const [estadoufh, setEstadoUFH] = useState('');
    const [pais, setPais] = useState('');

    const [cidade, setCidade] = useState("");

    const [hospedagem, setHospedagem] = useState(null);

    const [cadastro, setCadastro] = useState(null);

    const [dataCidade, setDataCidade] = useState("");
    const [dataUf, setDataUf] = useState("");
    const [selectedUf, setSelectedUf] = useState("0");

    const [telhospedagem, setTelHospedagem] = useState("");
    const [endereco, setEndereco] = useState("");

    const [localreserva, setLocalreserva] = useState("");

    const [id_municipio, setIdmunicipio] = useState(0);

    const [agencia, setAgencia] = useState('');

    const [tipoTransporte, setTipoTransporte] = useState("");

    const [placaOnibus, setPlacaOnibus] = useState([]);




    const localReserva = [
        { key: 3, value: "Airbnb" },
        { key: 8, value: "Alugue Temporada" },
        { key: 1, value: "Booking" },
        { key: 4, value: "Expedia" },
        { key: 5, value: "Hotel Urbano - Hurb" },
        { key: 7, value: "Kayak" },
        { key: 12, value: "Reserva direta com hospedagem" },
        { key: 12, value: "Reserva no balcão" },
        { key: 6, value: "TripAdvisor" },
        { key: 9, value: "Tripping" },
        { key: 2, value: "Trivago" },
        { key: 10, value: "Troca Casa" },
        { key: 11, value: "Outros" },
    ]


    const tipo = [
        { key: 1, value: "Apartamento" },
        { key: 2, value: "Camping" },
        { key: 3, value: "Casa Temporada" },
        { key: 4, value: "Chalé" },
        { key: 5, value: "Hotel" },
        { key: 6, value: "Hostel" },
        { key: 7, value: "Pousada" },
        { key: 8, value: "Rancho" },

    ]

    const tipoVeiculo = [
        { key: 1, value: "Aéreo" },
        { key: 2, value: "Automóvel" },
        { key: 3, value: "Motocicleta" },
        { key: 4, value: "MotorHome" },
        { key: 5, value: "Ônibus de Excursão" },
        { key: 6, value: "Transporte Rodoviário" },
    ]

    const tipoVeiculoAgencia = [
        { key: 5, value: "Onibus de Excursão" },
        { key: 6, value: "Transporte Rodoviário" },
    ]



    const handleVoltar = () => {
        localStorage.setItem("@cadastro:Etapa5", JSON.stringify({
            pais,
            estadouf,
            estadoufh,
            cidade,

            acomodacao,
            nomeAcomodacao,
            veiculo,
            placa,

            telhospedagem,
            endereco,
            localreserva


        }));

        navigation("/contato");
    }

    useEffect(() => {

        const buscaPlacaOnibus = async () => {
            const { data: res, status: state } = await API.get("/findOnibus/");

            if (state === 200) {
                setPlacaOnibus(res.onibus);
            }

        }

        buscaPlacaOnibus()

        const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa5"));

        const Hospedagem = JSON.parse(localStorage.getItem("@passagem:Etapa"));

        const cadastroNovo = JSON.parse(localStorage.getItem("@passagem:Etapa0"));

        const IdMunicipio = localStorage.getItem("id_municipio");

        const cadAgencia = localStorage.getItem("@agencia:Etapa2");

        if (IdMunicipio) {
            setIdmunicipio(IdMunicipio);
        }

        if (Hospedagem != null) {
            setHospedagem(Hospedagem.passagem);
        }

        if (cadastroNovo != null) {
            setCadastro(cadastroNovo.cadastro);
        }

        if (dados != null) {
            setEstadoUF(dados.estadouf);
            setEstadoUFH(dados.estadoufh);
            setPais(dados.pais);

            setCidade(dados.cidade);

            setAcomodacao(dados.acomodacao);
            setNomeAcomodacao(dados.nomeAcomodacao);
            setVeiculo(dados.veiculo);
            setPlaca(dados.placa);

            setTelHospedagem(dados.telhospedagem);
            setEndereco(dados.endereco);
        }

        if (cadAgencia) {
            setAgencia(cadAgencia);
            if (cadAgencia.length > 0) {
                setTipoTransporte(tipoVeiculoAgencia);
            }
        } else {
            setTipoTransporte(tipoVeiculo);
        }



    }, []);

    const TelefoneMask = (value, info) => {

        if (value.length <= 17) {
            setTelHospedagem(value);
        }
    }

    //////////////////////// Rotina buscar cidade e estado //////////////////////////

    useEffect(() => {
        if (selectedUf === "0") {
            return;
        }
        try {
            axios
                .get(
                    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios`
                )
                .then((response) => {
                    setDataCidade(response.data);

                });

        }
        catch (err) {
            console.log("Erro ao buscar dados da api: ", err);
        }

    }, [selectedUf]);

    useEffect(() => {
        axios
            .get("https://servicodados.ibge.gov.br/api/v1/localidades/estados/")
            .then((response) => {
                setDataUf(response.data);
            });
    }, [pais]);

    function handleSelectUf(value) {
        setSelectedUf(value);
        setEstadoUF(value);
    }

    function handleSelectCity(value) {
        setCidade(value);
    }




    ////////////////////////////////////////////////////////////////////////////////////////////////

    async function handleSalvarCadastro(event) {
        event.preventDefault();

        localStorage.setItem("@cadastro:Etapa5", JSON.stringify({
            pais,
            estadouf,
            estadoufh,
            cidade,

            acomodacao,
            nomeAcomodacao,
            veiculo,
            placa,
            telhospedagem,
            endereco,
            localreserva
        }))


        const etapaP = JSON.parse(localStorage.getItem("@passagem:Etapa"));
        const etapa1 = JSON.parse(localStorage.getItem("@cadastro:Etapa1"));
        const etapa2 = JSON.parse(localStorage.getItem("@cadastro:Etapa2"));
        const etapa3 = JSON.parse(localStorage.getItem("@cadastro:Etapa3"));
        const etapa4 = JSON.parse(localStorage.getItem("@cadastro:Etapa4"));
        const etapa5 = JSON.parse(localStorage.getItem("@cadastro:Etapa5"));

        //await API.post("/viagem", {
        await API.post("/viagemNew", {
            id_municipio: id_municipio,
            tipo_turismo: etapa1.tipo_turismo,
            viajando_com: etapa1.viajando_com,
            conhece_municipio: etapa1.conhece_municipio,
            data_chegada: etapa1.data_chegada,
            data_partida: etapa1.data_partida,
            visitante: {
                nome: etapa2.visitante.nome,
                sobrenome: etapa2.visitante.sobrenome,
                tipoIdentificacao: etapa2.visitante.tipoIdentificacao,
                identificacao: etapa2.visitante.identificacao,
                data_nascimento: etapa2.visitante.data_nascimento,
                sexo: etapa2.visitante.sexo,
                escolaridade: etapa2.visitante.escolaridade,
                compartilha_dados: etapa2.visitante.compartilha_dados,
                pais: etapa5.pais,
                cidade: etapa4.cidade,
                email: etapa4.email,
                telefone: etapa4.emergenciaTel,
                nacionalidade: etapa2.visitante.nacionalidade,
                password: etapa2.visitante.password
            },
            acompanhante: etapa3,
            acomodacao: {
                tipo: etapa5.acomodacao,
                nome: etapa5.nomeAcomodacao
            },
            transporte: {
                tipo: etapa5.veiculo,
                placa: etapa5.placa
            },
            emergencia: {
                nome: etapa4.nome,
                parentesco: etapa4.parentesco,
                telefone: etapa4.telefone
            },
            passagem: {
                hospedado_outro_municipio: etapaP.passagem.hospedado_outro_municipio,
                nao_hospedado: etapaP.passagem.nao_hospedado
            },
            local_reserva: etapa5.localreserva,
            agencia: agencia
        }).then((result) => {
            //localStorage.clear();
            navigation(`/concluido/${result.data.newViagem._id}/${result.data.newViagem.data_chegada}/${result.data.newViagem.data_partida}`)
        }).catch((error) => {
            alert(error.message);
        })


    }

    // console.log("Valor do veiculo: ", veiculo);


    return (
        <form className="container" onSubmit={handleSalvarCadastro}>

            <section className="home_topo_conteudo">
                <img src={LogoColor} alt="logo" className="logoColor" />
                <img src={LogoColor} alt="logo" className="logo" />
            </section>

            {hospedagem &&

                <div className="corpo flex">

                    {(hospedagem?.nao_hospedado === true && cadastro?.novo_cadastro !== true) &&

                        <>
                            <div className="text1-black">Informações do veículo</div>
                            <div className="text2">Preencha os dados do perfil. Isso levará alguns segundos. <br /><strong>Depois ganhe tempo na visitação dos pontos turísticos!</strong></div>

                            <div className="titleinput">É importante que as informações do transporte estejam corretas</div>


                            <div className="textInput">Transporte</div>
                            <Dropdown
                                data={tipoVeiculo}
                                placeholder=""
                                msnWarning="Informe o transporte utilizado."
                                value={veiculo}
                                onchange={item => setVeiculo(item.target.value)}
                                required={true}
                            />
                            {veiculo !== "Transporte Rodoviário" && veiculo !== "Ônibus de Excursão" && veiculo !== "Aéreo" &&
                                <InputText
                                    title="Placa"
                                    placeholder="Ex: ABC-1234"
                                    value={placa}
                                    onchange={item => setPlaca(item.target.value)}
                                    msnWarning="Informe a placa do veiculo"
                                />
                            }


                        </>


                    }

                    {
                        ((hospedagem?.hospedado_outro_municipio === true && cadastro?.novo_cadastro !== false) || (hospedagem?.hospedado_outro_municipio === true && cadastro?.estou_de_passagem === true)) &&

                        <>

                            <div className="text1-black">Município hospedado</div>
                            <div className="text2">Preencha os dados do perfil. Isso levará alguns segundos. <br /><strong>Depois ganhe tempo na visitação dos pontos turísticos!</strong></div>



                            <div className="titleinput">Qual a localização de sua hospedagem?</div>
                            <div className="containerbloco" >


                                <InputText
                                    title="Qual País você está hospedado?"
                                    placeholder=""
                                    value={pais}
                                    onchange={(item) => setPais(item.target.value)}
                                    msnWarning="Informe o País que você está hospedaddo."
                                    required={true}
                                />


                                <div className="textInput"> Qual Estado você está hospedado? </div>
                                <DropdownUf
                                    data={dataUf}
                                    placeholder={estadouf ? estadouf : "Selecionar Estado"}
                                    onchange={(event) => handleSelectUf(event.target.value)}
                                    value={estadouf}
                                    msnWarning="Informe o Estado que você mora."
                                    required={true}
                                />

                                {estadouf &&
                                    <>
                                        <div className="textInput"> Qual Cidade você está hospedado? </div>

                                        <DropdownCity
                                            data={dataCidade}
                                            placeholder="Selecionar Cidade"
                                            onchange={(event) => handleSelectCity(event.target.value)}
                                            value={cidade}
                                            msnWarning="Informe o Estado que você mora."
                                            required={true}
                                        />
                                    </>
                                }

                            </div>

                        </>
                    }



                    {
                        ((hospedagem?.cadastro_em_outro_municipio === true && cadastro?.novo_cadastro === true) || (hospedagem?.hospedado_outro_municipio === true)) &&

                        <>
                            <div className="text1-black">Hospedagem e transporte</div>
                            <div className="text2">Preencha os dados do perfil. Isso levará alguns segundos. <br /><strong>Depois ganhe tempo na visitação dos pontos turísticos!</strong></div>

                            <div className="titleinput">Por qual tipo de hospedagem e transporte você optou?</div>
                            <div className="textInput">Tipo de Hospedagem</div>

                            <Dropdown
                                data={tipo}
                                placeholder=""
                                msnWarning="Ïnforme o tipo de hospedagem utilizado."
                                value={acomodacao}
                                onchange={item => setAcomodacao(item.target.value)}
                                required={true}
                            />
                            <InputText
                                title="Nome da Hospedagem"
                                placeholder="Ex: Hotel ...,  Pousada...,"
                                value={nomeAcomodacao}
                                onchange={item => setNomeAcomodacao(item.target.value)}
                                msnWarning="Informe o tipo de hospedagem"
                                required={true}
                            />

                            <div className="textInput">Local da reserva!</div>
                            <Dropdown
                                data={localReserva}
                                placeholder=""
                                msnWarning="Ïnforme o local da reserva!"
                                value={localreserva}
                                onchange={item => setLocalreserva(item.target.value)}
                                required={true}
                            />

                            {(acomodacao === "Casa Temporada" || acomodacao === "Apartamento" || acomodacao === "Camping") &&

                                <InputText
                                    title="Endereço da Hospedagem"
                                    placeholder="Av. Avelino..."
                                    value={endereco}
                                    onchange={item => setEndereco(item.target.value)}
                                    msnWarning="Informe o endereço da hospedagem"
                                    required={true}
                                />

                            }


                            <div className="textInput marginTop marginBotton">É importante que as informações do transporte estejam corretas </div>


                            <div className="textInput">Transporte</div>
                            <Dropdown
                                data={tipoVeiculo}
                                placeholder=""
                                msnWarning="Informe o transporte utilizado."
                                value={veiculo}
                                onchange={item => setVeiculo(item.target.value)}
                                required={true}
                            />
                            {veiculo !== "Transporte Rodoviário" && veiculo !== "Ônibus de Excursão" && veiculo !== "Aéreo" &&
                                <InputText
                                    title="Placa"
                                    placeholder="Ex: ABC-1234"
                                    value={placa}
                                    onchange={item => setPlaca(item.target.value)}
                                    msnWarning="Informe a placa do veiculo"
                                />
                            }
                        </>


                    }

                    {
                        (cadastro?.novo_cadastro === true && cadastro?.estou_de_passagem === false && cadastro?.atualizar === false) &&

                        <>
                            <div className="text1-black">Hospedagem e transporte</div>
                            <div className="text2">Preencha os dados do perfil. Isso levará alguns segundos. <br /><strong>Depois ganhe tempo na visitação dos pontos turísticos!</strong></div>

                            <div className="titleinput">Por qual tipo de hospedagem e transporte você optou?</div>

                            <div className="containerbloco" class="col-5 flex">

                                <div>

                                    <div className="textInput">Canal de reserva!</div>
                                    <Dropdown
                                        data={localReserva}
                                        placeholder=""
                                        msnWarning="Ïnforme o canal de reserva. Ex:Booking"
                                        value={localreserva}
                                        onchange={item => setLocalreserva(item.target.value)}
                                        required={true}
                                    />

                                </div>

                                <div>
                                    <div className="textInput">Tipo de Hospedagem</div>
                                    <Dropdown
                                        data={tipo}
                                        placeholder=""
                                        msnWarning="Ïnforme o tipo de hospedagem utilizado."
                                        value={acomodacao}
                                        onchange={item => setAcomodacao(item.target.value)}
                                        required={true}
                                    />

                                </div>

                            </div>



                            <InputText
                                title="Nome da Hospedagem"
                                placeholder="Ex: Hotel ...,  Pousada...,"
                                value={nomeAcomodacao}
                                onchange={item => setNomeAcomodacao(item.target.value)}
                                msnWarning="Informe o tipo de hospedagem"
                                required={true}
                            />

                            {(acomodacao === "Casa Temporada" || acomodacao === "Apartamento" || acomodacao === "Camping") &&

                                <InputText
                                    title="Endereço da Hospedagem"
                                    placeholder="Av. Avelino..."
                                    value={endereco}
                                    onchange={item => setEndereco(item.target.value)}
                                    msnWarning="Informe o endereço da hospedagem"
                                    required={true}
                                />

                            }




                            <div className="textInput"> Contato da Hospedagem</div>

                            <MuiTelInput
                                id="telefone"
                                variant="outlined"
                                placeholder="(00) 00000 0000"
                                defaultCountry="BR"
                                value={telhospedagem}
                                onChange={TelefoneMask}
                                flagSize="medium"
                                forceCallingCode


                                inputProps={
                                    {
                                        style: { fontSize: 15, border: 10 }
                                    }
                                }

                            />


                            <div className="titleinput marginTop marginBotton">É importante que as informações do transporte estejam corretas </div>


                            <div className="containerbloco" class="col-5 flex">
                                <div>

                                    <div className="textInput">Transporte</div>
                                    <Dropdown

                                        data={tipoVeiculo}
                                        placeholder=""
                                        msnWarning="Informe o transporte utilizado."
                                        value={veiculo}
                                        onchange={item => setVeiculo(item.target.value)}
                                        required={true}
                                    />

                                </div>

                                <div>

                                    {veiculo !== "Transporte Rodoviário" && veiculo !== "Ônibus de Excursão" && veiculo !== "Aéreo" &&
                                        <InputText
                                            title="Placa"
                                            placeholder="Ex: SLL1P57"
                                            value={placa}
                                            onchange={item => setPlaca(item.target.value)}
                                            msnWarning="Informe a placa do veiculo"
                                        />
                                    }

                                </div>



                            </div>



                            <div>




                            </div>

                        </>


                    }




                    <button className="btncadastrar" type="submit">Salvar Cadastro</button>
                </div>
            }
            <Rodape
                voltar={() => handleVoltar()}
                valorBar={100}
            />

        </form>
    )
}

export default CadastroAcomTrans;