
import API from "../service/api";

export const BuscaVeiculoAgenciaByCNPJ= async (cnpj)  =>{

    const { data : resposnse } = await API.post('/veiculoagenciabycnpj/',{cnpj}
        
    );

    return resposnse
}