import React, { useState } from 'react';

const CopyLink = ({ textToCopy }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
        } catch (err) {
            console.error('Falha ao copiar link: ', err);
        }
    };

    return (
        <div >
            <button className="btncopiar" onClick={handleCopy}>
                {copied ? 'Copido!' : 'Copiar linha digitável'}
            </button>
        </div>
    );
};

export default CopyLink;
