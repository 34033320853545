
// utils
import API from "../service/api";


// ----------------------------------------------------------------------



function jwtDecode(token) {
  
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  // \\console.log("Token decodificado payload Utils.js : ", JSON.parse(jsonPayload));

  return JSON.parse(jsonPayload);
}


// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {

  if (!accessToken) {
    return false;

  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  // \\console.log(" token decoded : ", decoded);
  return decoded.exp > currentTime;
};

export const decodeValidToken = (accessToken) => {

  if (!accessToken) {
    return false;

  }

  const decoded = jwtDecode(accessToken);

  //const currentTime = Date.now() / 1000;

  return decoded;
};


// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Login expirado');

    localStorage.removeItem('accessToken');

    // window.location.href = './login';
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    
    API.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // Esta função abaixo irá lidar com quando o token expirar
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');

    delete API.defaults.headers.common.Authorization;
  }
};
