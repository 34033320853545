import Logo from "../../assets/image/logo.svg";
import LogoColor from "../../assets/image/logo_color.svg";
import GooglePlay from "../../assets/image/googlePlay.svg";
import Appstore from "../../assets/image/appStore.svg";

import Facebook from "../../assets/image/facebook.svg";
import Instagram from "../../assets/image/instagram.svg";
import Twiter from "../../assets/image/twiter.svg";
import { Link } from "react-router-dom";


import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";


const IdentidadeMunicipal = () => {
    

    const carousel = useRef();
    const [width, setwidth] = useState(0);

    useEffect(() => {
        setwidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth)
    }, [])



    return (

        <section className="container">

            <section className="home_corpo2">
                <div className="pesquisa">

                <img src={LogoColor} alt="Logo" />

                    <div>
                        <div className="PL">Termos Legais</div>
                        <div className="ID-">Política de Privacidade</div>
                    </div>
                    <div className="ID2">
                        A missão do Sllips é se tornar um instrumento imprescindível nos municípios turísticos em todo o país. . Em outras palavras, construir um mundo onde todos possam se sentir seguros ao visitar qualquer lugar. Somos uma comunidade construída com base na confiança. Para conquistar essa confiança, é fundamental que demonstremos com clareza como usamos suas informações e protegemos seu direito humano à privacidade.

                        <p />Esta Política de Privacidade descreve como a Sllips Ltda, processam informações pessoais em relação ao seu uso da Plataforma Sllips. Dependendo de onde você mora e o que você está fazendo na Plataforma Sllips, as páginas de privacidade complementares listadas abaixo podem se aplicar a você. Por favor, clique nos links e revise as informações complementares que descrevem como processamos informações pessoais para aquelas regiões e serviços.

                        <p />INFORMAÇÕES COMPLEMENTARES IMPORTANTES

                        <p /><strong>1. DEFINIÇÕES</strong>

                        Termos não definidos nesta Política de Privacidade têm a mesma definição que em nossos Termos de Serviço (“Termos”).

                        <p /><strong>2. INFORMAÇÕES PESSOAIS QUE COLETAMOS</strong>

                        <p /><strong>2.1 Informações necessárias para usar a Plataforma Sllips.</strong>

                        <br /><br />Nós coletamos informações pessoais sobre você quando você usa a Plataforma Sllips. Sem essas informações, poderemos não ser capazes de fornecer todos os serviços que você solicitar. Essas informações incluem:

                        <ul>
                            <p /><li><strong>Informações de contato, informações de perfil.</strong> Como nome,
                                sobrenome, número de telefone, endereço postal, endereço de email, data de nascimento,
                                CPF dos quais alguns dependerão dos recursos que você usa.</li>
                        </ul>

                        <p /><strong>2.2 Informações que você optar por nos fornecer.</strong>

                        <ul>
                            <p />Você pode optar por nos fornecer informações pessoais adicionais. Essas informações podem incluir:

                            <p /><li><strong>Informações de perfil adicionais.</strong> Como gênero, idiomas,
                                escolaridade, profissão, cidade e descrição pessoal. Algumas dessas informações,
                                conforme indicado nas configurações da sua Conta, fazem parte da página do seu
                                perfil público e serão publicamente visíveis.</li>

                            <p /><li><strong>Informações sobre outras pessoas.</strong> Como um instrumento
                                de pagamento pertencente a outra pessoa ou informações sobre um colega de viagem.
                                Ao nos fornecer informações pessoais sobre outras pessoas, você garante que
                                tem permissão para fornecer essas informações ao Sllips para os fins descritos
                                nesta Política de Privacidade, que compartilhou a Política de Privacidade do
                                Sllips com elas, e que elas leram e entenderam que se aplica a elas.</li>

                            <p /><li><strong>Informações de contato do catálogo de endereços.</strong> Contatos
                                do catálogo de endereços que você importa ou insere manualmente.</li>

                            <p /><li><strong>Outras informações.</strong> Por exemplo, quando você preencher
                                um formulário, adicionar informações à sua conta, responder a pesquisas,
                                postar em fóruns da comunidade, participar de promoções, se comunicar
                                com nossa equipe de atendimento ao cliente ou com outros Membros e
                                quando compartilhar sua experiência conosco. Isso pode incluir informações
                                de saúde se você optar por compartilhá-las conosco.</li>

                        </ul>


                        <p /><strong>2.3 Informações coletadas automaticamente quando você usa a Plataforma Sllips e nossos Serviços de Pagamento.</strong>

                        <p />Quando você usa a Plataforma Sllips e os Serviços de Pagamento, nós coletamos automaticamente informações pessoais. Essas informações podem incluir:

                        <ul>

                            <p /><li><strong>Informações de geolocalização.</strong> Tal como a localização precisa ou aproximada determinada a partir do seu endereço IP ou GPS do dispositivo móvel, dependendo das configurações do seu dispositivo. Também podemos coletar essas informações quando você não estiver usando o aplicativo se você habilitar isso por meio de suas configurações ou permissões de dispositivo.</li>

                            <p /><li><strong>Informações de uso.</strong> Como páginas e conteúdo que você visualiza, buscas por Anúncios, reservas que você fez e outras ações na Plataforma Sllips.</li>

                            <p /><li><strong>Dados de log e informações do dispositivo.</strong> Como detalhes sobre como você usou a Plataforma Sllips (incluindo se você clicou em links para aplicativos de terceiros), endereço de IP, datas e horários de acesso, informações de hardware e software, informações do dispositivo, informações de eventos do dispositivo, identificadores exclusivos, dados de falha, dados de cookies e as páginas que você visualizou ou interagiu antes e depois de usar a Plataforma Sllips. Podemos coletar essas informações mesmo que você não tenha criado ou acessado uma conta do Sllips.</li>

                            <p /><li><strong>Cookies e tecnologias semelhantes, conforme descrito em nossa Política de cookies.</strong></li>

                            <p /><li><strong>Informações de transação de pagamento.</strong> Como instrumento de pagamento usado, data e hora, valor do pagamento, data de vencimento do instrumento de pagamento e código postal de cobrança, endereço de email do PayPal, informações do IBAN, seu endereço e outras informações relacionadas à transação.</li>

                        </ul>


                        <p /><strong>2.4 Informações pessoais que coletamos de terceiros.</strong>

                        <p />Nós coletamos informações pessoais de outras fontes, como:

                        <ul>
                            <p /><li><strong>Serviços de terceiros.</strong> Se você vincula, se conecta ou faz login na Plataforma Sllips por um serviço de terceiro
                                (por exemplo, Google, Facebook, WeChat), você direciona o serviço a nos enviar informações como seu registro, sua lista de amigos e
                                suas informações de perfil, conforme for controlado por esse serviço ou autorizado por você nas configurações de privacidade daquele serviço.</li>

                            <p /><li><strong>Informações de antecedentes.</strong> Para Membros nos Estados Unidos, conforme permitido pelas leis aplicáveis,
                                nós poderemos obter, por exemplo, atestados de antecedentes criminais, registros de prática de crimes sexuais e outras informações
                                sobre você e/ou seus antecedentes. Para Membros fora dos Estados Unidos, conforme permitido pelas leis aplicáveis e com seu
                                consentimento quando necessário, nós poderemos obter a versão local de atestados de antecedentes criminais ou de registros de
                                prática de crimes sexuais. Nós podemos utilizar suas informações, incluindo seu nome completo e data de nascimento, para obter esses atestados e registros.</li>

                            <p /><li><strong>Convites para produtos corporativos e gerenciamento de contas.</strong> As organizações que usam nossos produtos
                                corporativos podem enviar informações pessoais para facilitar o gerenciamento de contas e convites para usar produtos corporativos.</li>

                            <p /><li><strong>Indicações e colegas de viagem.</strong> Se você for convidado para a Plataforma Sllips, por exemplo,
                                como outro viajante de uma viagem, a pessoa que o convidou poderá enviar informações pessoais sobre você, como seu endereço de email ou outras informações de contato.</li>

                            <p /><li><strong>Outras fontes.</strong>Conforme permitido pela lei aplicável, nós podemos receber informações adicionais sobre você,
                                como referências, dados demográficos ou informações que nos ajudem a detectar fraudes e problemas de segurança, de parceiros
                                e/ou terceiros prestadores de serviço, e combinar essas informações com as que temos sobre você. Por exemplo, podemos receber
                                resultados de verificação de antecedentes ou avisos de fraude de prestadores de serviço de verificação de identidade para usar
                                em nossos esforços para prevenção de fraudes e avaliação de riscos. Podemos receber informações sobre você e suas atividades dentro e
                                fora da Plataforma Sllips, ou sobre suas experiências e interações com nossos parceiros. Podemos receber informações de saúde, incluindo,
                                dentre outras, informações de saúde relacionadas a doenças contagiosas.</li>

                        </ul>

                        <p /><strong>3. COMO USAMOS AS INFORMAÇÕES QUE COLETAMOS</strong>

                        <p />Se você reside fora dos Estados Unidos, clique aqui para saber mais sobre nossas bases legais para coleta e processamento de informações pessoais. Nós usamos informações pessoais conforme descrito nesta Política de Privacidade.

                        <p /><strong>3.1 Fornecer, melhorar e desenvolver a Plataforma Sllips.</strong> Tais como:

                        <ul>
                            <p /><li>permitir que você acesse a Plataforma Sllips e faça e receba pagamentos;</li>
                            <br /><li>permitir que você se comunique com outros Membros;</li>
                            <br /><li>processar suas solicitações;</li>
                            <br /><li>realizar análises, depurações e pesquisas;</li>
                            <br /><li>fornecer atendimento ao cliente e treinamentos;</li>
                            <br /><li>enviar mensagens, atualizações, alertas de segurança e notificações da conta para você;</li>
                            <br /><li>se você nos fornecer informações dos seus contatos, como amigos ou colegas de viagem, podemos processar esses dados para: (i) facilitar os convites de indicação, (ii) compartilhar os detalhes de sua viagem e facilitar seu planejamento, (iii) detectar e prevenir fraudes, (iv) facilitar seus pedidos e para qualquer outra finalidade que você autorize;</li>
                            <br /><li>personalizar e customizar sua experiência com base em suas interações com a Plataforma Sllips, seu histórico de buscas e reservas, suas informações e preferências de perfil e outros conteúdos que você enviar; e
                                permitir o seu uso de nossos produtos corporativos.</li>
                        </ul>

                        <p /><strong>3.2 Criar e manter um ambiente seguro e confiável.</strong> Incluindo:

                        <ul>
                            <p /><li>detectar e prevenir fraude, spam, abuso, incidentes de segurança e outras atividades nocivas;</li>
                            <br /><li>estudar e combater a discriminação em conformidade com nossa Política de Não Discriminação;</li>
                            <br /><li>realizar investigações de segurança e avaliações de risco,</li>
                            <br /><li> verificar ou autenticar as informações fornecidas por você;</li>
                            <br /><li>realizar verificações nos bancos de dados e em outras fontes de informação, incluindo verificações de antecedentes ou consultas policiais;</li>
                            <br /><li>cumprir com nossas obrigações legais, proteger a saúde e bem-estar de nossos Hóspedes, Anfitriões, funcionários dos Anfitriões e membros do público;</li>
                            <br /><li>resolver litígios com os nossos Membros, incluindo o compartilhamento de informações com o seu coanfitrião ou Hóspedes adicionais sobre litígios relacionados ao seu papel como coanfitrião ou Hóspede adicional;</li>
                            <br /><li>aplicar nossos contratos com terceiros;</li>
                            <br /><li>cumprir a lei, responder a requerimentos legais, prevenir danos e proteger nossos direitos (ver seção 4.5);</li>
                            <br /><li>fazer cumprir nossos Termos e outras políticas (por exemplo, Política de Não Discriminação); e</li>
                            <br /><li>em conexão com as atividades acima, podemos traçar perfis com base em suas interações com a Plataforma Sllips, suas informações de perfil, outros conteúdos que você enviar ao Sllips e informações obtidas de terceiros. Em casos limitados, os processos automatizados, que analisam sua conta e atividades na plataforma Sllips, bem como informações relacionadas a atividades dentro e fora da plataforma Sllips que podem ser associadas a você, podem restringir ou suspender seu acesso à Plataforma Sllips se detectarem atividades que possam representar riscos de segurança ou outros quaisquer ao Sllips, nossa comunidade ou terceiros. Se você quiser contestar decisões baseadas em processos automatizados, fale conosco através da seção Informações de Contato abaixo.</li>

                        </ul>

                        <p /><strong> 3.3 Fornecer, personalizar, medir e melhorar nossa publicidade e nosso marketing.</strong>
                        <br />Por exemplo, para:

                        <ul>
                            <p /><li>enviar a você mensagens promocionais, marketing, publicidade e
                                outras informações com base nas suas preferências e publicidade nas mídias
                                sociais através de plataformas de mídia social;</li>
                            <br /><li>personalizar, avaliar e melhorar nossa publicidade;</li>
                            <br /><li>administrar programas de indicação, recompensas, pesquisas,
                                sorteios, concursos ou outras atividades promocionais ou eventos, patrocinados
                                ou gerenciados pelo Sllips ou por nossos parceiros de negócio terceirizados;</li>
                            <br /><li>analisar características e preferências para enviar a você mensagens
                                promocionais, marketing, publicidade e outras informações que julgarmos
                                ser de seu interesse; e</li>
                            <br /><li>convidar você para eventos e oportunidades relevantes.</li>
                        </ul>

                        <p /><strong>3.4 Prestar Serviços de Pagamento.</strong> As informações pessoais são usadas para habilitar ou autorizar terceiros a utilizar os Serviços de Pagamento, como:

                        <ul>
                            <p /><li>detectar e prevenir lavagem de dinheiro, fraude, abuso e incidentes de segurança,</li>
                            <br /><li>realizar investigações de segurança e avaliações de risco,</li>
                            <br /><li>cumprir obrigações legais (tais como regulamentações contra lavagem de dinheiro),</li>
                            <br /><li>aplicar as condições dos Termos de Pagamento e de outras políticas de pagamento,</li>
                            <br /><li>com o seu consentimento, enviar mensagens promocionais, marketing, publicidade e outras informações que possam ser do seu interesse, com base nas suas preferências, e</li>
                            <br /><li>fornecer e melhorar os Serviços de Pagamento.</li>
                        </ul>

                        <p /><strong>4. COMPARTILHAMENTO E DIVULGAÇÃO</strong>

                        <p />Se você reside fora dos Estados Unidos, saiba mais sobre as garantias nas quais confiamos para transferir informações pessoais para destinatários fora do EEE aqui.

                        <p /><strong>4.1 Compartilhamento com o seu consentimento ou sob suas instruções.</strong>

                        <p />Quando você dá seu consentimento, compartilhamos suas informações conforme descrito no momento do consentimento, como ao autorizar um aplicativo ou site de terceiros a acessar sua conta do Sllips ou participar de atividades promocionais de parceiros do Sllips ou terceiros.

                        <p />Quando permitido pela legislação aplicável, podemos usar determinadas informações a seu respeito, como seu endereço de email, desidentificando-as e compartilhando-as com plataformas de mídia social para gerar oportunidades, direcionar o tráfego para o Sllips ou divulgar nossos produtos e serviços.

                        <p /><strong>4.2 Compartilhamento entre Membros.</strong>

                        <p />Para facilitar reservas ou outras interações entre Membros, podemos ter que compartilhar determinadas informações, tais como:

                        <ul>
                            <p /><li>Quando um pedido de reserva é feito, quando há um coanfitrião ou um litígio é enviado, determinadas informações podem ser compartilhadas entre Hóspedes e Anfitriões, incluindo perfil, nome, nomes de quaisquer Hóspedes adicionais, histórico de cancelamento, informações de avaliação, idade do hóspede (a menos que seja proibido pela legislação aplicável), resultado do litígio (quando aplicável) e outras informações que você opte por compartilhar e enviar. Quando uma reserva é confirmada, informações adicionais são compartilhadas para ajudar na coordenação da viagem, como sua foto de perfil e seu número de telefone. Quando você, como Anfitrião, tem uma reserva confirmada, algumas informações são compartilhadas com o Hóspede (e com os Hóspedes adicionais que ele convidar, se for o caso) para coordenar reserva, como seu perfil, nome completo, seu número de telefone e o endereço do Anúncio.</li>
                            <br /><li>Quando você, como Anfitrião, convida outro Membro para se tornar seu Coanfitrião, você autoriza o Coanfitrião a acessar e atualizar suas informações e seu Conteúdo de Membro, incluindo, sem limitar a, informações como seu nome completo, seu número de telefone, endereço da Acomodação, calendário, informações e fotos dos Anúncios e endereço de email.</li>
                            <br /><li>Quando você, como Hóspede, convida Hóspedes adicionais para uma reserva, seu nome completo, datas de viagem, nome do Anfitrião, informações do Anúncio, endereço da Acomodação e outras informações relacionadas serão compartilhadas com cada Hóspede adicional.</li>
                        </ul>

                        <p /><strong>4.3 Informações que você publica em perfis, Anúncios e outras informações públicas.</strong>

                        Você pode tornar certas informações publicamente visíveis para outras pessoas, como:

                        <ul>
                            <p /><li>Sua página de perfil pública, que inclui sua foto de perfil, nome (ou iniciais, quando aplicável), descrição e cidade.</li>
                            <br /><li>Páginas de Anúncios, que incluem informações como a descrição aproximada ou precisa da localização da Acomodação ou da Experiência, a disponibilidade do calendário, a foto do perfil, as informações de demanda agregada (como o número de visualizações da página ao longo de um período de tempo) e as informações adicionais que você optar por compartilhar.</li>
                            <br /><li>Avaliações, classificações e outros comentários públicos.</li>
                            <br /><li>Conteúdo em uma comunidade ou fórum de discussão, post de blog ou mídia social.</li>
                        </ul>

                        <p />Podemos exibir partes do seu perfil público e outros Conteúdos que você disponibiliza ao público, como detalhes do Anúncio, em sites, plataformas e aplicativos de terceiros.

                        <p />As informações que você compartilhar de forma pública na Plataforma Sllips podem ser indexadas através de mecanismos de busca de terceiros. Em alguns casos, você pode remover esse recurso nas configurações de sua conta.

                        <p /><strong>4.4 Prestadores de serviços de hospedagem.</strong>

                        <p />Os Anfitriões podem usar serviços de terceiros para ajudá-los a gerenciar ou prestar seus serviços, como serviços de limpeza ou fornecedores de fechaduras. Os Anfitriões podem usar recursos na Plataforma Sllips para compartilhar informações sobre o Hóspede (como datas de check-in e checkout, nome do Hóspede, número de telefone do Hóspede) com esses prestadores de serviços terceirizados.

                        <p /><strong>4.5 Cumprimento da lei, resposta a solicitações legais, prevenção de danos e proteção de nossos direitos.</strong>

                        <p />Podemos compartilhar suas informações com tribunais, autoridades policiais, governamentais ou públicas, autoridades fiscais ou terceiros autorizados, se e na medida em que formos obrigados ou autorizados a fazê-lo por lei ou quando o compartilhamento for razoavelmente necessário para: (i) cumprir nossas obrigações legais, (ii) cumprir requerimento legal válido (como uma intimação ou ordem judicial) ou responder a reclamações feitas contra o Sllips, (iii) responder a requerimento legal válido relacionado a uma investigação criminal sobre denúncia ou suspeita de atividade ilegal, ou para responder ou abordar qualquer outra atividade que possa expor o Sllips, você ou qualquer outro de nossos usuários a responsabilidade legal ou regulatória (mais informações sobre as Diretrizes para Autoridades Policias do Sllips aqui), (iv) administrar e fazer cumprir nossos acordos com Membros, incluindo nossosTermos, Termos Legais Adicionais e Políticas, ou (v) proteger os direitos, propriedade ou segurança pessoal do Sllips, seus funcionários, seus Membros ou membros do público. Por exemplo, se permitido conforme as circunstâncias expostas anteriormente, as informações fiscais do Anfitrião poderão ser compartilhadas com as autoridades fiscais ou outras agências governamentais.

                        <p />Quando apropriado, podemos notificar Membros sobre requerimentos legais, a menos que: (i) a notificação seja proibida pelo próprio procedimento legal, por ordem judicial que recebermos, ou por lei aplicável; ou (ii) acreditemos que a notificação não teria utilidade, seria ineficaz, criaria um risco de lesão ou dano físico a um indivíduo ou grupo, ou criaria ou aumentaria um risco de fraude ou dano ao Sllips, nossos Membros, ou exporia o Sllips a uma reclamação de obstrução da justiça.

                        <p />Quando legalmente exigido ou permitido de acordo com a lei aplicável, podemos divulgar as informações dos Anfitriões e/ou Hóspedes às autoridades fiscais para fins de determinação do cumprimento adequado das obrigações fiscais relevantes. As obrigações fiscais relevantes incluem as obrigações fiscais do Sllips sobre suas taxas de serviço, sua mediação e retenção de impostos sobre acomodações e obrigações fiscais individuais dos Anfitriões. As informações que podem ser compartilhadas incluem, entre outras, nomes de Anfitriões e Hóspedes, endereços de anúncios, endereços de Anfitriões, número(s) de identificação fiscal/comercial, data de nascimento e/ou informações de contato, números de identificação de propriedades, informações de pagamento, datas e valores de transações, número de noites e reservas de Hóspedes, valor bruto e líquido da reserva e valores de pagamento, impostos cobrados pelo Sllips em nome de Hóspedes e Anfitriões, na medida em que qualquer uma dessas informações seja conhecida pelo Sllips.

                        <p />Em jurisdições em que o Sllips facilita ou requer um registro, notificação, permissão, licença ou número de um Anfitrião junto a uma autoridade governamental, poderemos compartilhar informações dos Anfitriões interessados com a autoridade competente, tanto durante o processo de requerimento de publicação do Anúncio, quanto periodicamente depois, como o nome completo e as informações de contato do Anfitrião, endereço da Acomodação, número de identificação fiscal, número do registro, permissão ou licença, informações do Anúncio, informações da reserva e número de noites reservadas, de acordo com as leis aplicáveis.

                        <p /><strong>4.6 Programas com administradores e proprietários.</strong>

                        <p />Podemos compartilhar informações pessoais de Anfitriões e Hóspedes com locadores, empresas de gestão e/ou proprietários de imóveis ("Administração do Condomínio"), como informações de reserva e informações relacionadas ao cumprimento das leis aplicáveis, a fim de facilitar programas que envolvam a Administração do Condomínio. Por exemplo, a reserva do hóspede e suas informações pessoais, incluindo informações de contato, podem ser compartilhadas com a Administração do Condomínio do edifício, complexo ou comunidade onde vive o anfitrião e/ou onde o anúncio está localizado, para facilitar os serviços de hospedagem, a conformidade com as leis aplicáveis, a segurança, a cobrança e outros serviços.

                        <p /><strong>4.7 Informações de Anfitriões fornecidas a clientes do Sllips for Work.</strong>

                        <p />Se uma reserva designada como sendo para fins comerciais ou de trabalho (1) for feita por um Hóspede afiliado a uma Empresa e (2) a Empresa estiver inscrita no Sllips for Work, poderemos compartilhar informações relacionadas à reserva com a Empresa (por exemplo, nome do Anfitrião, endereço da Acomodação, datas de reserva, detalhes do Anúncio, etc.) na medida em que for necessário para a execução adequada do contrato do Sllips com a Empresa e para o fornecimento dos serviços contratados. A pedido da Empresa ou do Hóspede, também poderemos compartilhar essas informações com terceiros contratados pela Empresa para fornecer serviços de suporte.

                        <p /><strong>4.8 Prestadores de serviço.</strong>

                        <p />Compartilhamos informações pessoais com prestadores de serviço afiliados e não afiliados para facilitar a administração de nossos negócios e para fins de conformidade, incluindo prestadores de serviço que nos ajudam a: (i) verificar a sua identidade ou autenticar seus documentos de identificação; (ii) verificar informações em bancos de dados públicos; (iii) realizar verificações de vida pregressa e de antecedentes criminais, prevenção de fraudes e avaliação de riscos; (iv) realizar desenvolvimento, manutenção e depuração de produtos; (v) permitir a prestação de Serviços do Sllips através de plataformas e ferramentas de software de terceiros (por exemplo, através da integração com as nossas APIs); (vi) fornecer serviços de atendimento ao cliente, publicidade ou pagamentos; ou (vii) processar, tratar ou avaliar reclamações de seguros ou semelhantes. Esses prestadores são contratualmente obrigados a proteger suas informações pessoais e têm acesso a elas para realizar essas tarefas.

                        <p /><strong>4.9 Transferências de negócios.</strong>

                        <p />Caso o Sllips realize ou esteja envolvido em qualquer fusão, aquisição, reestruturação, venda de ativos, falência ou caso de insolvência, poderemos vender, transferir ou compartilhar alguns ou todos os nossos ativos, incluindo suas informações relacionadas ou contempladas por tais transações (por exemplo, "due diligence" ou diligência prévia). Neste caso, você será notificado antes que suas informações pessoais sejam transferidas e estaejam sujeitas a uma política de privacidade diferente.

                        <p /><strong>4.10 Afiliados corporativos.</strong>

                        <p />Para nos ajudar a fornecer, integrar, promover e melhorar a Plataforma Sllips, os Serviços de Pagamento e os serviços de nossos afiliados, podemos compartilhar informações pessoais dentro de nosso grupo corporativo de empresas relacionadas por propriedade ou controle comum. Alguns exemplos são:

                        <p />Compartilhamento com o Sllips, Inc. Mesmo que o seu país de residência não seja os Estados Unidos, suas informações serão compartilhadas com o Sllips, Inc., que fornece a infraestrutura técnica para a Plataforma Sllips.

                        <p />O compartilhamento de dados descrito acima é necessário para a execução do contrato entre você e o Sllips, na medida em que possibilita que você anuncie ou reserve Serviços de Anfitrião e se conecte com Membros na China. As agências governamentais chinesas exigem que o Sllips China divulgue informações de anfitriões e anúncios relacionados a acomodações na China e informações de reservas e check-in de hóspedes relacionadas a reservas na China. Assim como outras empresas de hospedagem ou locação por temporada que têm negócios na China, o Sllips China poderá compartilhar as informações acima às agências governamentais chinesas sem aviso prévio. Suas informações podem ser compartilhadas com prestadores de serviço (inclusive na China) para ajudar na operação dos nossos negócios. Nossos prestadores de serviço também podem compartilhar suas informações quando exigido por lei.

                        <p /><strong>5. OUTRAS INFORMAÇÕES IMPORTANTES</strong>

                        <p /><strong>5.1 Análise de suas comunicações.</strong>

                        <p />Podemos avaliar, examinar ou analisar suas comunicações na Plataforma Sllips pelos motivos descritos na seção “Como usamos as informações que coletamos” desta política, incluindo prevenção de fraudes, avaliação de riscos, conformidade regulatória, investigações, desenvolvimento de produtos, pesquisas, análises, aplicação de nossos Termos de Serviço e para fins de atendimento ao cliente. Por exemplo, como parte de nossos esforços para prevenção de fraudes, nós podemos examinar e analisar mensagens que tentem mascarar informações de contato e referências a outros sites. Em alguns casos, nós também podemos examinar, avaliar ou analisar mensagens para depurar, melhorar e expandir a oferta de produtos. Nós utilizamos métodos automatizados quando razoavelmente possível. Ocasionalmente, poderá ser necessário que revisemos comunicações manualmente, como em casos de investigações de fraudes, de atendimento ao cliente, ou para avaliar e melhorar a funcionalidade dos referidos métodos automatizados. Nós não avaliaremos, examinaremos ou analisaremos suas comunicações para enviar mensagens publicitárias de terceiros e nós não venderemos análises e avaliações destas comunicações.

                        <p /><strong>5.2 Vinculação com contas de terceiros.</strong>

                        <p />Você pode vincular sua conta do Sllips a determinados serviços de terceiros, como redes sociais. Seus contatos nesses serviços de terceiros são chamados de "Amigos". Ao conduzir o compartilhamento de dados com essa vinculação:

                        <ul>
                            <p /><li>algumas das informações fornecidas a nós a partir da vinculação de contas poderão ser publicadas em seu perfil público;</li>
                            <br /><li>suas atividades na Plataforma Sllips poderão ser exibidas a seus Amigos na Plataforma Sllips e/ou no serviço daquele terceiro;</li>
                            <br /><li>um link para seu perfil público naquele serviço de terceiro poderá ser incluído no seu perfil público do Sllips;</li>
                            <br /><li>outros usuários do Sllips poderão visualizar os Amigos em comum que você possa ter com eles ou que você é Amigo de um dos Amigos deles, se aplicável;</li>
                            <br /><li>outros usuários do Sllips poderão visualizar instituições de ensino, cidade natal ou outros grupos que você tenha em comum com eles conforme listado no seu serviço de rede social vinculado;</li>
                            <br /><li>as informações que você nos fornece a partir da vinculação de suas contas poderão ser armazenadas, processadas e transmitidas para fins de prevenção de fraude e avaliação de riscos; e</li>
                            <br /><li>a publicação e exibição de informações que você fornece à Plataforma Sllips por meio dessa vinculação estarão sujeitas às suas configurações e autorizações na Plataforma Sllips e no serviço do terceiro.</li>
                        </ul>

                        <p /><strong>5.3 Integrações e terceiros parceiros.</strong>

                        <p />Partes do Sllips podem ser vinculadas a serviços de terceiros, não pertencentes ou controlados pelo Sllips, como o Google Maps/Earth. O uso desses serviços está sujeito às políticas de privacidade desses fornecedores, como os Termos de Uso Adicionais do Google Maps/Earth, a Política de Privacidade do Google (clique aqui para saber mais sobre como o Google usa suas informações) e a Política de Privacidade do Citi. O Sllips não é proprietário nem controla esses terceiros e, quando você interage com eles, está fornecendo suas informações a eles.

                        <p /><strong>6. SEUS DIREITOS</strong>

                        <p />Você pode exercer qualquer um dos direitos descritos nesta seção de acordo com a legislação aplicável. Clique aqui para saber mais sobre solicitações de direitos do titular de dados e sobre como enviar uma solicitação. Podemos pedir que você confirme sua identidade e sua solicitação antes de tomar qualquer providência em relação à ela.

                        <p /><strong>6.1 Gerenciamento de suas informações.</strong>

                        <p />Você pode acessar e atualizar algumas de suas informações pessoais através das configurações de sua Conta. Se você vinculou sua Conta do Sllips a um serviço de terceiros, como Facebook ou Google, pode alterar suas preferências e desvinculá-la desses serviços nas configurações de sua Conta. Você é responsável por manter suas informações pessoais atualizadas.

                        <p /><strong>6.2 Acesso e portabilidade de dados.</strong>

                        <p />Em algumas jurisdições, a lei aplicável pode dar a você o direito de solicitar algumas cópias de suas informações pessoais ou informações sobre como lidamos com suas informações pessoais, solicitar cópias de informações pessoais que você nos forneceu em um formato estruturado, comumente usado e legível eletronicamente, e/ou solicitar que enviemos essas informações a outro provedor de serviços (quando tecnicamente viável).

                        <p /><strong>6.3 Eliminação de dados.</strong>

                        <p />Em algumas jurisdições, você pode solicitar que suas informações pessoais sejam excluídas. Por favor, leve em conta que, se você solicitar a exclusão de suas informações pessoais, ou se sua conta for suspensa, encerrada ou voluntariamente cancelada:

                        <ul>
                            <p /><li>Poderemos reter algumas de suas informações pessoais conforme for necessário para nossos interesses comerciais legítimos, como prevenção de lavagem de dinheiro, detecção e prevenção de fraude e melhorias de segurança. Por exemplo, se suspendermos uma Conta do Sllips por motivos de fraude ou segurança, poderemos reter certas informações da conta em questão para evitar que aquele Membro abra uma nova conta no futuro.</li>
                            <br /><li>Poderemos reter e usar suas informações pessoais na medida necessária para cumprir nossas obrigações legais. Por exemplo, o Sllips e o Sllips Payments podem manter algumas de suas informações para cumprir obrigações relacionadas a impostos, relatórios legais e auditorias.</li>
                            <br /><li>As informações que você compartilhou com outras pessoas (por exemplo, Avaliações e publicações em fóruns) poderão continuar visíveis publicamente no Sllips, mesmo depois que sua Conta do Sllips for cancelada. No entanto, a atribuição de tais informações a você será removida. Algumas cópias de suas informações (por exemplo, registros de log) poderão permanecer em nosso banco de dados, mas serão desassociadas de identificadores pessoais.</li>
                            <br /><li>Como mantemos o Sllips de forma a evitar perda e destruição acidental ou maliciosa, cópias residuais de suas informações pessoais poderão permanecer em nossos sistemas de backup por um período de tempo limitado.</li>

                        </ul>

                        <p /><strong>7. SEGURANÇA</strong>

                        <p />Embora nenhuma organização possa garantir uma segurança perfeita, nós implementamos e atualizamos continuamente medidas de segurança administrativa, técnica e física para auxiliar na proteção de suas informações contra acesso, perda, destruição ou alteração sem autorização.

                        <p /><strong>8. ALTERAÇÕES A ESTA POLÍTICA DE PRIVACIDADE</strong>

                        <p />Nós nos reservamos o direito de modificar esta Política de Privacidade a qualquer momento, de acordo com a lei aplicável. Se fizermos isso, publicaremos a Política de Privacidade revisada e atualizaremos a data de "Última Atualização" na parte superior. Em caso de alterações significativas, também forneceremos a você um aviso sobre as modificações, por email, pelo menos 30 (trinta) dias antes da data prevista para entrarem em vigor. Caso você não concorde com a Política de Privacidade revisada, poderá cancelar sua Conta. Caso você não cancele sua Conta antes da data de vigência da Política de Privacidade revisada, seu acesso e/ou uso continuado da Plataforma Sllips estarão sujeitos à Política de Privacidade revisada.

                        <p /><strong>9. INFORMAÇÕES DE CONTATO E ENTIDADES RESPONSÁVEIS DO SLLIPS</strong>

                        <p />Para dúvidas ou reclamações sobre esta Política de Privacidade ou sobre o tratamento de informações pessoais pelo Sllips: (i) se você reside nos Estados Unidos, entre em contato com o Sllips, Inc., Legal Privacy, 888 Brannan Street, San Francisco, CA 94103 USA; (ii) para questões relacionadas a pagamentos, use as informações de contato fornecidas na página Termos de Serviço de Pagamentos; e (iii) se você reside fora dos Estados Unidos, use as informações de contato do seu controlador fornecidas na página Fora dos Estados Unidos.


                    </div>

                    <div className="linha"></div>




                </div>

            </section>



            <section className="home_rodape">
                <div className="box1 flex">


                    <div className="bloco1">

                        <div className="text1">Visitante</div>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Cadastro Turístico Municipal</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Fazer Cadastro CTM</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Ajuda e suporte</div>

                        <Link className="box" to="/InformaçõesDaEmpresa">
                            <div className="text2">Central de ajuda</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Perguntas Frequentes</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Empresa</div>

                        <Link className="box" to="/NossaHistoria">
                            <div className="text2">Nossa história</div>
                        </Link>

                    </div>
                </div>

                <div className="box2 flex">
                    <div>
                        <div className="baixe">Baixe agora</div>
                        <img src={GooglePlay} alt="Google Play" />
                    </div>
                    <div>
                        <div className="disponivel">O app esta disponível <br />apenas para atividade <br />comerciais</div>
                        <img src={Appstore} alt="App Store" />
                    </div>
                </div>
            </section>
            <section className="home_rodape">

                <div className="text">© 2022 Sllips LTDA. All rights reserved</div>

                <Link className="a" to="/Privacidade">
                    <div className="text">.Política de Privacidade</div>
                </Link>

                <Link className="a" to="/InformaçõesDaEmpresa">
                    <div className="text">.Informações da empresa</div>
                </Link>

                <div className="social">

                    <Link className="a" to="https://www.instagram.com/sllips.oficial/">
                        <img src={Instagram} alt="Instagram" />
                    </Link>
                    <img src={Facebook} alt="facebook" />
                    <img src={Twiter} alt="Twiter" />

                </div>
            </section>
        </section>
    )
}

export default IdentidadeMunicipal;