
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// import Logo from "../../assets/image/logo_color.svg";
import Dropdown from "../../components/Dropdown";
import Rodape from "../../components/Rodape";
// import "../../styles/cadastro-novo.css";

import LogoColor from "../../assets/image/logo_color.svg";


const CadastroNovo = () => {
    const navigation = useNavigate();

    const [chegada, setChegada] = useState("");
    const [partida, setPartida] = useState("");
    const [viajandoCom, setViajandoCom] = useState("");
    const [tipoturismo, setTipoTurismo] = useState("");
    const [conhecemunicipio, setConheceMunicipio] = useState("");

    const cadastro = {
        id_municipio: null,
        tipo_turismo: tipoturismo,
        viajando_com: viajandoCom,
        data_chegada: chegada,
        data_partida: partida,
        conhece_municipio: conhecemunicipio,
        // visitante: {
        //     nome: null,
        //     sobrenome: null,
        //     cpf: null,
        //     sexo: null,
        //     data_nascimento: null,
        //     escolaridade: null,
        //     compartilha_dados: null,
        //     cidade: null,
        //     email: null,
        //     telefone: null,
        // },
        // acompanhantes: [
        //     null
        // ],
        // acomodacao: {
        //     tipo: null,
        //     nome: null,
        // },
        // transporte: {
        //     tipo: null,
        //     placa: null,
        // },
        // emergencia: {
        //     nome: null,
        //     parentesco: null,
        //     telefone: null,
        // }
    };



    const options = [
        { key: 1, value: "Turismo Comercial" },
        { key: 2, value: "Turismo Educativo" },
        { key: 3, value: "Turismo de Excursão" },
        { key: 4, value: "Turismo Evento" },
        { key: 5, value: "Turismo de Lazer" },
        { key: 6, value: "Turismo Negócios" },
        { key: 7, value: "Turismo Religioso" },
        { key: 8, value: "Turismo Saúde" },
        { key: 9, value: "Visita Familiar" },

    ];

    const options2 = [
        { key: 1, value: "Família" },
        { key: 2, value: "Amigos" },
        { key: 3, value: "Sozinho" },
    ];

    const handleOptionChange = (e) => {
        setConheceMunicipio(e.target.value);

    }

    const handleAvanca = () => {
        localStorage.setItem("@cadastro:Etapa1", JSON.stringify(cadastro));
        navigation("/visitante");
    }

    useEffect(() => {
        const dados = JSON.parse(localStorage.getItem("@cadastro:Etapa1"));
        if (dados != null) {
            setChegada(dados.data_chegada);
            setPartida(dados.data_partida);
            setViajandoCom(dados.viajando_com);
            setTipoTurismo(dados.tipo_turismo);
            setConheceMunicipio(dados.conhece_municipio);
        }

    }, []);

    return (
        <form className="container" onSubmit={handleAvanca}>

            <section className="home_topo_conteudo">
                <img src={LogoColor} alt="logo" className="logoColor" />
                <img src={LogoColor} alt="logo" className="logo" />
            </section>

            <section className="corpo flex">

                <div className="ContainerTitulo marginBotton">
                    <div className="titulo-G">Me conte mais sobre a viagem!</div>
                </div>


                <div className="containerbloco" class="col-5 flex">



                    <div>

                        <div className="TitleCampo">Motivo da viagem</div>
                        <Dropdown
                            data={options}
                            //placeholder="Selecione o motivo"
                            onchange={(item) => setTipoTurismo(item.target.value)}
                            value={tipoturismo}
                            msnWarning='Selecione o tipo de turismo realizado.'
                            required={true}

                        />


                    </div>

                    <div>

                        <div className="TitleCampo">Você está viajando</div>
                        <Dropdown
                            data={options2}
                            //placeholder="Selecione"
                            onchange={(item) => setViajandoCom(item.target.value)}
                            value={viajandoCom}
                            msnWarning='Selecione se possui mais alguém com você.'
                            required={true}
                        />


                    </div>


                </div>


                <div className="containerbloco">

                    <div className="TitleCampo">Qual o seu período de permanência?</div>
                    <div className="col-5 flex">
                        <fieldset className="borda-legenda">
                            <legend className="legenda">Chegada</legend>
                            <input required className="data" type="date" value={chegada} onChange={(item) => setChegada(item.target.value)} />
                        </fieldset>

                        <fieldset className="borda-legenda">
                            <legend className="legenda">Partida</legend>
                            <input required className="data" type="date" value={partida} onChange={(item) => setPartida(item.target.value)} />
                        </fieldset>
                    </div>


                </div>


                <div className="containerbloco">
                    <div className="TitleCampo">Você já vistou nosso município</div>
                    <div className="col-2 flex">
                        <div>
                            <input type="radio" value="false" name="visitei" checked={conhecemunicipio === 'false'} onChange={handleOptionChange} />
                            <label className="textvisita">Não! É a primeira visita!</label>
                        </div>
                        <div>
                            <input type="radio" value="true" name="visitei" checked={conhecemunicipio === 'true'} onChange={handleOptionChange} />
                            <label className="textvisita">Sim! Já visitei outras vezes!</label>
                        </div>
                    </div>


                </div>



            </section>




            <Rodape
                valorBar={33.3}
                voltar={() => navigation(-1)}
                avancar={true}
            />

        </form>

    )
}
export default CadastroNovo;