import React, { createContext, useEffect, useReducer, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import API from './api';
import localStorageAvailable from '../utils/localStorageAvailable';
import { isValidToken, setSession, decodeValidToken } from '../utils/utils';

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const jwt = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (jwt && isValidToken(jwt)) {
        setSession(jwt);

        const user = decodeValidToken(jwt);

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error('Erro', error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const login = useCallback(async (email, password) => {

    try {
      const response = await API.post('/login', {
        email: email,
        password: password,
      });

      const { user, token } = response.data;

      localStorage.setItem('userNome', user.nome);
      setSession(token);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    } catch (error) {
      if (error.response && error.response.status === 401 && error.response.data && error.response.data.message === 'Sem permissão de acesso.') {
        logout();
      } else {
        console.error('Erro durante o login:', error);
      }
    }
  }, [logout]);

  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await API.post('/API/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { user, jwt } = response.data;

    localStorage.setItem('accessToken', jwt);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);


  const checkPagePermission = () => {
    // const { user } = state;

    if (state.isAuthenticated && state.user && state.user.nivel_acesso === 1) {
      logout();
      return false;
    }

    return true;
  };

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      logout,
      checkPagePermission,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, login, logout, register]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
