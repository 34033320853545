import { useNavigate } from "react-router-dom";
// import Logo from "../../assets/image/logo_color.svg";
import { useState, useEffect, useRef } from "react";
import InputText from "../../components/InputText";
import Rodape from "../../components/Rodape";
import IMask from "imask";
import InputMask from 'react-input-mask';

import LogoColor from "../../assets/image/logo_color.svg";

//vc tem que importar o componente para poder utilizar ele na pagina, com isso vc pode usar o mesmo item em mais de um lugar  
import Dropdown from "../../components/Dropdown";

import API from "../../service/api";

const CadastroAcompanhante = () => {

    const navigation = useNavigate();

    const [responsavel, setResponsavel] = useState(false);
    const [nome, setNome] = useState("");
    const [sobrenome, setSobrenome] = useState("");
    const [sexo, setSexo] = useState("");
    const [nascimento, setNascimento] = useState("");
    const [cpf, setCpf] = useState("");
    const [nacionalidade, setNacionalidade] = useState("");

    const [listaAcompanhante, setlistaAcompanhante] = useState([]);

    const [colaborador, setColaborador] = useState("");




    const handleAddLista = () => {

        const index = listaAcompanhante.findIndex(item => item.cpf === cpf);

        if (index !== -1) {
            listaAcompanhante[index].nome = nome;
            listaAcompanhante[index].sobrenome = sobrenome;
            listaAcompanhante[index].sexo = sexo;
            listaAcompanhante[index].data_nascimento = nascimento;
            listaAcompanhante[index].cpf = cpf;
            listaAcompanhante[index].nacionalidade = nacionalidade;
            listaAcompanhante[index].colaborador = colaborador;
       
        } else {
            listaAcompanhante.push({
                nome: nome,
                sobrenome: sobrenome,
                sexo: sexo,
                data_nascimento: nascimento,
                cpf: cpf,
                nacionalidade: nacionalidade,
                colaborador: colaborador,

            });
        }

        localStorage.setItem("@agencia:Etapa3", JSON.stringify(listaAcompanhante))
        setNome("");
        setSobrenome("");
        setSexo("");
        setNascimento("");
        setCpf("");
        setNacionalidade("");
        setColaborador("");
    };

    const opcoes = [
        { key: 1, value: 'Motorista' },
        { key: 2, value: 'Guia Turístico' },
    
    ];


    const opcoes3 = [
        { key: 1, value: 'Brasileiro(a)' },
        { key: 2, value: 'Estrangeiro(a)' },
    ];


    const handleOptionChange = (e) => {
        setSexo(e.target.value);
    }

    const handlealterar = (e) => {
        const cpf = e.target.value;
        for (let index = 0; index < listaAcompanhante.length; index++) {
            const element = listaAcompanhante[index];
            if (element.cpf === cpf) {
                setNome(element.nome);
                setSobrenome(element.sobrenome);
                setSexo(element.sexo);
                setNascimento(element.data_nascimento);
                setCpf(element.cpf);
                setNacionalidade(element.nacionalidade);
                setColaborador(element.colaborador);
            }
        }
    }



    const handleExcluir = () => {
        const index = listaAcompanhante.findIndex(item => item.cpf === cpf);
        if (index !== -1) {
            listaAcompanhante.splice(index, 1);

            localStorage.setItem("@agencia:Etapa3", JSON.stringify(listaAcompanhante))
            setNome("");
            setSobrenome("");
            setSexo("");
            setNascimento("");
            setCpf("");
            setNacionalidade("");
            setColaborador("");
        }
    }


    useEffect(() => {
        const interval = setInterval(() => {
            (async () => {
                const dados = JSON.parse(localStorage.getItem("@agencia:Etapa3"));
                if (dados != null) {
                    setlistaAcompanhante(dados);
                }
            })();
        }, 1000);
        return () => clearInterval(interval);
    }, []);


    async function handleSalvarCadastro(event) {
        event.preventDefault();

        const agencia_etapa1 = JSON.parse(localStorage.getItem("@agencia:Etapa1"));
        const agencia_etapa2 = JSON.parse(localStorage.getItem("@agencia:Etapa2"));
        const agencia_etapa3 = JSON.parse(localStorage.getItem("@agencia:Etapa3"));
        const municipio_id = localStorage.getItem("id_municipio");

        //await API.post("/viagem", {
        await API.post("/Agencia", {
            agencia: agencia_etapa1,
            onibus: JSON.parse(JSON.stringify(agencia_etapa2)),
            colaborador: JSON.parse(JSON.stringify(agencia_etapa3)),
            id_municipio: municipio_id

        }).then((result) => {
            // navigation(`/concluido/${result.data.newViagem._id}/${result.data.newViagem.data_chegada}/${result.data.newViagem.data_partida}`)
             alert("Cadastro efetuado com sucesso!");

            navigation('/');

            console.log("Cadastro concluido")
        }).catch((error) => {
            alert(error.message);
        })


    }


    return (
        <form className="container" onSubmit={handleSalvarCadastro}>

            <section className="home_topo_conteudo">
                <img src={LogoColor} alt="logo" className="logoColor" />
                <img src={LogoColor} alt="logo" className="logo" />
            </section>

            <div className="corpo flex">
                <div>
                    <div className="text1-black">Cadastre seus colaboradores</div>
                    <div className="text2">É importante que todos os estejam colaboradores(as) estejam cadastrados(as).</div>

                    <InputText
                        title="Nome"
                        placeholder="Primeiro nome"
                        value={nome}
                        onchange={item => setNome(item.target.value)}
                        msnWarning="Obrigatório preencher o primeiro nome."
                    />

                    <InputText
                        title="Sobrenome"
                        placeholder="Segundo nome"
                        value={sobrenome}
                        onchange={item => setSobrenome(item.target.value)}
                        msnWarning="Obrigatório preencher o segundo nome"
                    />


                    <div className="titleinput">Qual seu sexo</div>
                    <div className="col-2 flex">
                        <div>
                            <input type="radio" value="Masculino" name="sexo" checked={sexo === "Masculino"} onChange={handleOptionChange} />
                            <label className="textRadio">Masculino</label>
                        </div>
                        <div>
                            <input type="radio" value="Feminino" name="sexo" checked={sexo === "Feminino"} onChange={handleOptionChange} />
                            <label className="textRadio">Feminino</label>
                        </div>
                        <div>
                            <input type="radio" value="Outros" name="sexo" checked={sexo === "Outros"} onChange={handleOptionChange} />
                            <label className="textRadio">Outros</label>
                        </div>
                    </div>

                    <div className="titleinput">Qual sua data de nascimento</div>
                    <InputText
                        type="date"
                        value={nascimento}
                        onchange={(item) => setNascimento(item.target.value)}
                        msnWarning="Campo precisa ser preenchido."
                    />

                    <div className="titleinput">CPF</div>
                    <InputMask
                        className="cpf-input"
                        mask="999.999.999-99"
                        maskChar="_"
                        type="text"
                        id="acompanhanteCPF"
                        style={{
                            fontFamily: "Poppins-Regular",
                            height: '5.6rem',
                            width: '97%',
                            marginBottom: '1rem',
                            border: '1px solid #b0b0b0',
                            borderRadius: '0.7rem',
                            color: '#666666',
                            fontSize: '1.6rem',
                        }}
                        name="cpf"
                        value={cpf}
                        onChange={event => setCpf(event.target.value)}
                    />

                    <div className="textInput">Nacionalidade</div>
                    <Dropdown
                        data={opcoes3}
                        placeholder=""
                        onchange={item => setNacionalidade(item.target.value)}
                        value={nacionalidade}
                        msnWarning="Campo obrigatório."
                        
                    />


                    <div className="titleinput">Atividade do colaborador</div>
                    <div className="textInput">Selecione a atividade</div>
                    <Dropdown
                        data={opcoes}
                        placeholder=""
                        onchange={item => setColaborador(item.target.value)}
                        value={colaborador}
                        msnWarning="Campo obrigatório."

                    />


                    <div className="btngroup">
                        <button className="btn deletar" type="button" onClick={() => handleExcluir()}>Deletar</button>
                        <button className="btn salvar" type="button" onClick={() => handleAddLista()}>Salvar</button>
                    </div>
                </div>

                <div className="linhadivisor" />

                <div className="titleinput">Motorista(s) e Guia(s)</div>

                {listaAcompanhante.map((item, index) => (
                    // <input key={index} className="input" type="text" value={item.nome} disabled />
                    <div className="listaAcompanhante flex" key={item.cpf}>
                        <input type="radio" name="lista" value={item.cpf} onChange={handlealterar} />
                        <label className="textInput maiuscula">{item.nome} {item.sobrenome} | {item.cpf}</label>
                    </div>
                ))}

                <div className="linhadivisor" />

                <button className="btncadastrar" type="submit">Salvar Cadastro</button>

            </div>

            <Rodape
                voltar={() => navigation("/onibus")}
                valorBar={100}
            />
        </form>
    )
}
export default CadastroAcompanhante;
