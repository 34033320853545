import Logo from "../../assets/image/logo.svg";
import LogoColor from "../../assets/image/logo_color.svg";
import GooglePlay from "../../assets/image/googlePlay.svg";
import Appstore from "../../assets/image/appStore.svg";

import Facebook from "../../assets/image/facebook.svg";
import Instagram from "../../assets/image/instagram.svg";
import Twiter from "../../assets/image/twiter.svg";
import { Link } from "react-router-dom";



import InputText from "../../components/InputText";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API from "../../service/api";



const Perfil = () => {

    const navigation = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [login, setLogin] = useState(false);


    async function handleAvancaLogin(e){
        
        e.preventDefault();

        try{

            const { data : response } = await API.get(`/visitantes/${email}/${password}`);

            if(response){
                if(response._id > 0){
                    setLogin(false);
                    navigation("/cadastro");
                }
            if(response.message){
                setLogin(true);
            }
                
            }else{

                setLogin(true);

            }

        }catch(error){
            console.log("Falaha: ", error);

        }

        
    }


    useEffect(() => {

        const dados = JSON.parse(localStorage.getItem("@atualize:Atualizar"));

        if (dados != null) {

            setEmail(dados.email);
            setPassword(dados.password);

        }
    }, []);



    return (

        <form className="container" onSubmit={handleAvancaLogin}>


            <section className="home_Atualizar">
                <div className="home_topo_conteudoCEOM">
                    <div className="tituloCEOM"><strong>Efetue login <br />para sua próxima viagem!</strong> </div>
                </div>

            </section>

            <section className="home_corpoCEOM">

                <div className="corpoA flex">

                    <div className="ContainerAtualizar">
                        <div className="text1">Login!</div>
                        <label className="text2"><br />Digite seu email e senha para efetuar login caso já tenha efetuado o cadastro!</label>
                    </div>

                    <InputText
                        type="email"
                        title="Email"
                        placeholder="Digite seu Email"
                        value={email}
                        onchange={item => setEmail(item.target.value)}
                        msnWarning="Informe seu email."
                        required={true}
                        autocomplete={email}
                    />

                    <InputText
                        type="password"
                        title="Senha"
                        placeholder="Digite sua senha"
                        value={password}
                        onchange={item => setPassword(item.target.value)}
                        msnWarning="Informe sua senha."
                        required={true}
                        autocomplete={""}
                    />

                    {login && <div className="text3">Usuário ou senha incorreto!</div>}
                    

                    <button className="btncadastrar" type="submit">ENTRAR</button>



                </div>



            </section>

            
            <section className="home_rodape">
                <div className="box1 flex">


                    <div className="bloco1">

                        <div className="text1">Visitante</div>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Cadastro Turístico Municipal</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Fazer Cadastro CTM</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Ajuda e suporte</div>

                        <Link className="box" to="/InformaçõesDaEmpresa">
                            <div className="text2">Central de ajuda</div>
                        </Link>

                        <Link className="box" to="/CadastroTurísticoMunicipal">
                            <div className="text2">Perguntas Frequentes</div>
                        </Link>

                    </div>


                    <div className="bloco1">

                        <div className="text1">Empresa</div>

                        <Link className="box" to="/NossaHistoria">
                            <div className="text2">Nossa história</div>
                        </Link>

                    </div>
                </div>

                <div className="box2 flex">
                    <div>
                        <div className="baixe">Baixe agora</div>
                        <img src={GooglePlay} alt="Google Play" />
                    </div>
                    <div>
                        <div className="disponivel">O app esta disponível <br />apenas para atividade <br />comerciais</div>
                        <img src={Appstore} alt="App Store" />
                    </div>
                </div>
            </section>
            <section className="home_rodape">

                <div className="text">© 2022 Sllips LTDA. All rights reserved</div>

                <Link className="a" to="/Privacidade">
                    <div className="text">.Política de Privacidade</div>
                </Link>

                <Link className="a" to="/InformaçõesDaEmpresa">
                    <div className="text">.Informações da empresa</div>
                </Link>

                <div className="social">

                    <Link className="a" to="https://www.instagram.com/sllips.oficial/">
                        <img src={Instagram} alt="Instagram" />
                    </Link>
                    <img src={Facebook} alt="facebook" />
                    <img src={Twiter} alt="Twiter" />

                </div>
            </section>


        </form>


    )
}
export default Perfil;